import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { Observable } from 'rxjs';
import { POPPER_CONTENT } from './popper-model';

@Component({
  selector: 'app-popper',
  template: '{{content$ | async}}',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'small-text',
  },
})
export class PopperComponent {
  constructor(@Inject(POPPER_CONTENT) public content$: Observable<string>) {}
}
