import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
} from '@angular/core';
import { DisplaysEntity } from 'angular-fire-utils';
import { combineLatest, race, Subject, timer } from 'rxjs';
import {
  delay,
  filter,
  map,
  pluck,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import { AuthService, Post, PostService } from '@rlmoves/api';
import { QueryService } from '@rlmoves/core';
import {
  NOTIFY_REMOVE,
  ASK_REMOVE,
  CONFIRM_REMOVE,
} from './post-display-model';

@Component({
  selector: 'app-post-display',
  templateUrl: './post-display.component.html',
  styleUrls: ['./post-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-post-display flex w-100 j-center',
  },
})
export class PostDisplayComponent extends DisplaysEntity<Post> {
  url$ = this.pluck('url');
  title$ = this.pluck('title');
  reactions$ = this.pluck('reactions');
  userId$ = this.pluck('userId');
  player$ = this.pluck('player');
  user$ = this.authService.getSignedInUser();

  removable$ = combineLatest([this.userId$, this.user$]).pipe(
    map(([userId, user]) => userId === user?.id)
  );

  private removeSub = new Subject<void>();
  private voteSub = new Subject<string | null>();

  removeLabel$ = this.removeSub.pipe(
    delay(0),
    switchMap(() =>
      race(
        this.removeSub.pipe(map(() => NOTIFY_REMOVE)),
        timer(2000).pipe(map(() => ASK_REMOVE))
      ).pipe(startWith(CONFIRM_REMOVE))
    ),
    startWith(ASK_REMOVE)
  );

  @Output('removed')
  remove$ = this.removeLabel$.pipe(
    withLatestFrom(this.id$),
    filter(([label, id]) => label === NOTIFY_REMOVE && !!id),
    pluck(1)
  );

  constructor(
    protected postService: PostService,
    protected authService: AuthService,
    private queryService: QueryService
  ) {
    super(postService);
    this.subscribe();
  }

  onVote(vote: string | null): void {
    this.voteSub.next(vote);
  }

  onPlayerClicked(playerName: string): void {
    this.queryService.query({ key: 'player', value: playerName });
  }

  onRemoveClicked(): void {
    this.removeSub.next();
  }

  private subscribe(): void {
    this.voteSub
      .pipe(
        withLatestFrom(this.id$, this.authService.getSignedInUser()),
        takeUntil(this.destroySub)
      )
      .subscribe(([vote, id, user]) => {
        if (user) {
          if (vote) {
            this.postService.setProperty(vote, id, 'reactions', user.id);
          } else {
            this.postService.removeProperty(id, 'reactions', user.id);
          }
        }
      });
  }
}
