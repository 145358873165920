// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC-mBE-aKfAlvJQLyP6LDA89TdJxR704ds',
    authDomain: 'rlmoves-1337.firebaseapp.com',
    databaseURL: 'https://rlmoves-1337-default-rtdb.firebaseio.com',
    projectId: 'rlmoves-1337',
    storageBucket: 'rlmoves-1337.appspot.com',
    messagingSenderId: '880181187690',
    appId: '1:880181187690:web:edaeb36d4c08abc67fa613',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
