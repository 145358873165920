import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserDisplayComponent } from './user-display.component';
import { PopperModule } from '../popper';

@NgModule({
  declarations: [UserDisplayComponent],
  exports: [UserDisplayComponent],
  imports: [CommonModule, PopperModule],
})
export class UserDisplayModule {}
