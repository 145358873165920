import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BingoBoardComponent } from './bingo-board.component';
import { GifPlayerModule } from '../gif-player';
import { IconModule } from '../icon';

@NgModule({
  declarations: [BingoBoardComponent],
  imports: [CommonModule, GifPlayerModule, IconModule],
  exports: [BingoBoardComponent],
})
export class BingoBoardModule {}
