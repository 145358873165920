import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { Move } from '@rlmoves/api';
import { BingoMove } from './bingo-board-model';

@Component({
  selector: 'app-bingo-board',
  templateUrl: './bingo-board.component.html',
  styleUrls: ['./bingo-board.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-bingo-board',
  },
})
export class BingoBoardComponent {
  @Input()
  set moves(values: Move[] | null) {
    this.activeMoves = (values || []).map((move) => ({ move, active: false })).slice(0, 16);
  }

  activeMoves: BingoMove[] = [];

  toggle(index: number): void {
    this.activeMoves[index] = {
      ...this.activeMoves[index],
      active: !this.activeMoves[index].active,
    };
  }
}
