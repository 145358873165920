import { Observable, Subject } from 'rxjs';
import { scan, shareReplay, take, startWith, map } from 'rxjs/operators';

import { addToArray, removeFromArray, updateInArray } from './store-util';
import { StoreAction, Action } from './store-model';

export class CrudStore<T> {
  private actionSub: Subject<StoreAction<T>> = new Subject();

  private entities$: Observable<T[]> = this.actionSub.pipe(
    scan<StoreAction<T>, T[]>((acc, curr) => {
      switch (curr.action) {
        case Action.Create: {
          return addToArray(curr.entity!, acc);
        }

        case Action.Remove: {
          return removeFromArray(curr.entity!, acc);
        }

        case Action.Update: {
          return updateInArray(curr.entity!, curr.target!, acc);
        }

        case Action.Clear: {
          return [];
        }

        default: {
          return acc;
        }
      }
    }, [] as T[]),
    startWith([]),
    map((entities) => (this.sortWith ? this.sortWith(entities) : entities)),
    shareReplay(1)
  );

  constructor(private sortWith?: (entitites: T[]) => T[]) {
    this.entities$.pipe(take(1)).subscribe();
  }

  create(entity: T): void {
    this.actionSub.next({ action: Action.Create, entity });
  }

  read(): Observable<T[]> {
    return this.entities$;
  }

  update(entity: T, target: T): void {
    this.actionSub.next({
      action: Action.Update,
      entity,
      target,
    });
  }

  delete(entity: T): void {
    this.actionSub.next({ action: Action.Remove, entity });
  }

  clear(): void {
    this.actionSub.next({ action: Action.Clear });
  }
}
