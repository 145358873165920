import { PostUrl, PostUrlType } from '@rlmoves/api';

export const HOST_URL = 'https://rlmoves.com';

export enum UrlAction {
  ShowGif = 's',
}

export interface PostUrlHandler {
  type: PostUrlType;
  match(url: string): boolean;
  getId(url: string): string;
  getUrl(id: string): string;
}

// export const YOUTUBE_HANDLER: PostUrlHandler = {
//   type: PostUrlType.YouTube,
//   match: (url: string) => {
//     return (
//       !!/https\:\/\/(www\.)?youtube.com\/.+/.exec(url) ||
//       !!/https\:\/\/(www\.)?youtu.be\/.+/.exec(url)
//     );
//   },
//   getId: (url: string) => {
//     const urls = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
//     return urls[2] !== undefined ? urls[2].split(/[^0-9a-z_\-]/i)[0] : urls[0];
//   },
//   getUrl: (id: string) => {
//     return 'https://www.youtube.com/embed/' + id;
//   },
// };

// export const GFYCAT_HANDLER: PostUrlHandler = {
//   type: PostUrlType.Gfycat,
//   match: (url: string) => {
//     return !!/.*gfycat.com\/.+/.exec(url);
//   },
//   getUrl: (id: string) => {
//     return 'https://gfycat.com/ifr/search/' + id + '?autoplay=0';
//   },
//   getId: getLastUrlPart,
// };

export const GYG_HANDLER: PostUrlHandler = {
  type: PostUrlType.Gyg,
  match: (url: string) => {
    return !!/.*gifyourgame.com\/.+/.exec(url);
  },
  getUrl: (id: string) => {
    return `https://media.gifyourgame.com/${id}_720p.mp4`;
  },
  getId: getLastUrlPart,
};

export const POST_URL_HANDLERS = [GYG_HANDLER];

export function getLastUrlPart(url: string): string {
  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
}

export function classifyUrl(url: string): PostUrlHandler | null {
  for (const handler of POST_URL_HANDLERS) {
    if (handler.match(url)) {
      return handler;
    }
  }

  return null;
}

export function urlToPostUrl(url: string): PostUrl | null {
  const handler = classifyUrl(url);

  if (handler === null) {
    return null;
  }

  return {
    type: handler.type,
    id: handler.getId(url),
  };
}

export function postUrlToUrl(postUrl: PostUrl): string | undefined {
  const handler = getPostUrlHandler(postUrl);
  return handler && handler.getUrl(postUrl.id);
}

function getPostUrlHandler(postUrl: PostUrl): PostUrlHandler | undefined {
  return POST_URL_HANDLERS.find((handler) => handler.type === postUrl.type);
}
