import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';

import { GifPlayer } from './gif-player-model';

@Component({
  selector: 'app-gif-player-full',
  templateUrl: './gif-player-full.component.html',
  styleUrls: ['./gif-player-full.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-gif-player-full w-100 h-100 c-pointer',
  },
})
export class GifPlayerFullComponent {
  get gifUrl() {
    return this.player.move.gifUrl;
  }

  get previewUrl() {
    return this.player.move.previewUrl;
  }

  get type() {
    return `video/${this.player.move.gifUrl.endsWith('mp4') ? 'mp4' : 'webm'}`;
  }

  constructor(@Inject(GifPlayer) private player: GifPlayer) {}
}
