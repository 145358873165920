import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';
import { fromEvent, of, Subject } from 'rxjs';
import { switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { ButtonParent, BUTTON_PARENT } from './button-model';

@Directive({
  selector: 'button',
  host: {
    class:
      'app-button app-border app-background app-text flex a-center j-center c-pointer',
    '[class.app-button-disabled]': 'disabled',
    '[class.medium-opaque]': '!selected',
  },
})
export class ButtonDirective implements OnDestroy {
  @Input() disabled: boolean | null;

  @Input()
  set value(value: any) {
    this.valueSub.next(value);
  }
  private valueSub = new Subject<any>();

  selected = true;

  private destroySub = new Subject<void>();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(BUTTON_PARENT) private buttonParent: ButtonParent
  ) {
    this.subscribe();
  }

  private subscribe(): void {
    this.valueSub
      .pipe(
        switchMap((value) =>
          this.buttonParent ? this.buttonParent.getActive(value) : of(false)
        ),
        takeUntil(this.destroySub)
      )
      .subscribe((selected) => (this.selected = selected));

    fromEvent(this.elementRef.nativeElement, 'click')
      .pipe(withLatestFrom(this.valueSub), takeUntil(this.destroySub))
      .subscribe(([, value]) => this.buttonParent?.toggleValue(value));
  }

  ngOnDestroy(): void {
    this.destroySub.next();
    this.destroySub.complete();
  }
}
