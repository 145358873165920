import {
  GlobalPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay) {}

  close(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  open(
    templateOrComponent: TemplateRef<any>,
    viewContainerRef: ViewContainerRef
  ): void;
  open(templateOrComponent: Type<any>, viewContainerRef?: undefined): void;
  open(
    templateOrComponent: TemplateRef<any> | Type<any>,
    viewContainerRef: any
  ): void {
    const overlay = this.createOverlay();

    overlay.attach(
      templateOrComponent instanceof Type
        ? new ComponentPortal(templateOrComponent)
        : new TemplatePortal(templateOrComponent, viewContainerRef)
    );

    overlay
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => this.close());
  }

  private createOverlay(): OverlayRef {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    const overlayConfig = this.createOverlayConfig();
    this.overlayRef = this.overlay.create(overlayConfig);

    return this.overlayRef;
  }

  private createOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this.createPositionStrategy(),
      scrollStrategy: this.overlay.scrollStrategies.close(),
      hasBackdrop: true,
      backdropClass: ['app-background', 'medium-opaque'],
      panelClass: ['app-background', 'app-border'],
    });
  }

  private createPositionStrategy(): GlobalPositionStrategy {
    return this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
  }
}
