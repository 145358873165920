import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-icon d-block p-relative c-pointer hoverable',
    '[class.app-icon-small]': 'size === "small"',
    '[class.app-icon-disabled]': 'disabled',
    '[class.app-icon-large]': 'size === "large"',
  },
})
export class IconComponent {
  @Input()
  set icon(value: string | null) {
    this.iconSrc = value ? `/assets/icon/${value}.svg` : '';
  }

  @Input() verticalAlign: string;

  @Input() href: string;

  @Input() disabled: boolean | null;

  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  iconSrc: string;
}
