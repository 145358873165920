<div class="app-container-title-container app-background">
  <ng-content select="app-container-title"></ng-content>
  <ng-content select="app-container-secondary-title"></ng-content>
</div>

<div class="app-container-flex w-100 flex j-space-between">
  <ng-content select="app-container-content"></ng-content>
  <ng-content select="app-container-full"></ng-content>
</div>

<ng-content select="app-container-subtitle"></ng-content>
