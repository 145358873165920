import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PostDisplayComponent } from './post-display.component';
import { ContainerModule } from '../container';
import { ExternalPlayerModule } from '../external-player';
import { ReactionDisplayModule } from '../reaction-display';
import { UserDisplayModule } from '../user-display';
import { LinkModule } from '../link';
import { HiddenOverflowModule } from '../hidden-overflow';
import { IconModule } from '../icon';
import { PopperModule } from '../popper';

@NgModule({
  declarations: [PostDisplayComponent],
  exports: [PostDisplayComponent],
  imports: [
    CommonModule,
    ContainerModule,
    ExternalPlayerModule,
    HiddenOverflowModule,
    IconModule,
    LinkModule,
    PopperModule,
    ReactionDisplayModule,
    UserDisplayModule,
  ],
})
export class PostDisplayModule {}
