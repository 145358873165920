<div class="lists">
  <ul class="app-nav-list">
    <li
      *ngFor="let move of moves"
      [routerLink]="'/wiki/' + move.link"
      [class.app-nav-move]="move.isMove"
    >
      {{ move.name }}
    </li>
  </ul>
</div>
