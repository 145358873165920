import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ButtonParent, BUTTON_PARENT } from '../button/button-model';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-button-group flex w-wrap s-4',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ButtonGroupComponent,
      multi: true,
    },
    {
      provide: BUTTON_PARENT,
      useExisting: ButtonGroupComponent,
    },
  ],
})
export class ButtonGroupComponent
  implements ControlValueAccessor, ButtonParent {
  private writeValueSub = new BehaviorSubject<any[]>([]);

  private onChange: (value: any) => void = () => {};

  @Input() mode: 'radio' | 'multi' = 'multi';

  getActive(value: any): Observable<boolean> {
    return this.writeValueSub.pipe(map((values) => values.includes(value)));
  }

  toggleValue(value: any): void {
    let newValues = [];
    const values = this.writeValueSub.value;

    if (this.mode === 'radio') {
      newValues = [value];
    } else {
      newValues = values.includes(value)
        ? values.filter((v) => v !== value)
        : [...values, value];
    }

    this.onChange(newValues);
    this.writeValue(newValues);
  }

  writeValue(obj: any): void {
    this.writeValueSub.next(obj ?? []);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}
}
