import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from '@rlmoves/components';

import { HomeComponent } from './home/home.component';
import { PostComponent } from './post/post.component';
import { WikiComponent } from './wiki/wiki.component';
import { FreestyleComponent } from './freestyle/freestyle.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      // {
      //   path: 'post',
      //   redirectTo: 'post/',
      //   pathMatch: 'full',
      // },
      // {
      //   path: 'post/:id',
      //   component: PostComponent,
      // },
      {
        path: '',
        redirectTo: 'wiki/',
        pathMatch: 'full',
      },
      {
        path: 'wiki',
        redirectTo: 'wiki/',
        pathMatch: 'full',
      },
      {
        path: 'wiki/:id',
        component: WikiComponent,
      },
      {
        path: 'wiki/:id/:action',
        component: WikiComponent,
      },
      {
        path: 'freestyle',
        redirectTo: 'freestyle/',
        pathMatch: 'full',
      },
      {
        path: 'freestyle/:id',
        component: FreestyleComponent,
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
