import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { MovesModule, NavigatorModule } from '@rlmoves/core';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { WikiModule } from './wiki/wiki.module';
import { PostModule } from './post/post.module';
import { FreestyleModule } from './freestyle/freestyle.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AppRoutingModule,
    FreestyleModule,
    BrowserModule,
    HomeModule,
    MovesModule,
    NavigatorModule,
    PostModule,
    WikiModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
