<ng-container *ngIf="nick$ | async as nick; else signTmpl">
  <div class="flex a-flex-end w-wrap s-8 w-100">
    <app-form-field>
      <app-form-field-label>Nick</app-form-field-label>
      <input [formControl]="nickFormControl" />
    </app-form-field>

    <button (click)="onLogOut()">Log out</button>

    <div class="large-text">Boost: {{ likes$ | async }}</div>
  </div>
</ng-container>

<ng-template #signTmpl>
  <form class="flex a-flex-end w-wrap s-8 w-100" [formGroup]="formGroup">
    <app-form-field>
      <app-form-field-label>Email</app-form-field-label>
      <input formControlName="email" />
    </app-form-field>

    <app-form-field>
      <app-form-field-label>Password</app-form-field-label>
      <input formControlName="password" type="password" />
    </app-form-field>

    <button (click)="onLogIn()" type="submit">Log in</button>

    <button (click)="onSignUp()">Sign up</button>

    <button
      (click)="onResetPassword()"
      *ngIf="formMessage === 'Invalid password'"
    >
      Reset password
    </button>

    <div class="app-secondary-text" *ngIf="formMessage">
      {{ formMessage }}
    </div>
  </form>
</ng-template>
