import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { GifPlayerComponent } from './gif-player.component';
import { IconModule } from '../icon';
import { GifPlayerFullComponent } from './gif-player-full.component';

@NgModule({
  declarations: [GifPlayerComponent, GifPlayerFullComponent],
  exports: [GifPlayerComponent, GifPlayerFullComponent],
  imports: [CommonModule, OverlayModule, IconModule],
})
export class GifPlayerModule {}
