import { FormGroup, ValidationErrors } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function getErrorFromFromGroup(
  key: string,
  formGroup: FormGroup
): Observable<ValidationErrors | null> {
  if (!formGroup.controls[key]) {
    return EMPTY;
  }

  return formGroup.controls[key].statusChanges.pipe(
    map(() => formGroup.controls[key].errors)
  );
}
