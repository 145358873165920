import { Injectable, Injector } from '@angular/core';

import { CrudApi } from 'angular-fire-utils';
import { ServerValue } from '@firebase/database';

import { User } from './user-model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CrudApi<User> {
  constructor(protected injector: Injector) {
    super(injector, 'users', 'session');
  }

  changeLike(id: string, delta: number): void {
    this.afd
      .object(`${this.getPath(id)}/likes`)
      .set(ServerValue.increment(delta));
  }
}
