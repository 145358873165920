export enum Action {
  Create,
  Remove,
  Update,
  Clear,
}

export interface StoreAction<T> {
  action: Action;
  entity?: T;
  target?: T;
}
