<div class="flex s-8 a-flex-end w-wrap" [formGroup]="formGroup">
  <app-form-field>
    <app-form-field-label [required]="true">Title</app-form-field-label>
    <input appInput formControlName="title" />
    <app-form-field-error *ngIf="titleError$ | async as titleError">
      {{ titleError }}
    </app-form-field-error>
  </app-form-field>

  <app-form-field>
    <app-form-field-label [required]="true">Gif url</app-form-field-label>
    <input appInput formControlName="url" placeholder="GifYourGame" />
    <app-form-field-error *ngIf="urlError$ | async as urlError">
      {{ urlError }}
    </app-form-field-error>
  </app-form-field>

  <app-form-field>
    <app-form-field-label>Player url (optional)</app-form-field-label>
    <input appInput formControlName="player" placeholder="Liquipedia" />
    <app-form-field-error *ngIf="playerError$ | async as playerError">
      {{ playerError }}
    </app-form-field-error>
  </app-form-field>

  <button (click)="onPost()" [disabled]="invalid$ | async">
    Post
  </button>
</div>
