import {
  Component,
  Directive,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-form-field flex d-column p-relative',
  },
})
export class FormFieldComponent {}

@Directive({
  selector: 'app-form-field-label',
  host: {
    class: 'p-relative app-text',
    '[class.app-form-field-label-required]': 'required',
  },
})
export class FormFieldLabelComponent {
  @Input() required: boolean;
}

@Directive({
  selector: 'app-form-field-error',
  host: {
    class: 'app-form-field-error app-error-text',
  },
})
export class FormFieldErrorComponent {}
