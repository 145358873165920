import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  IconModule,
  LogoModule,
  PopperModule,
  ToolbarModule,
  FormFieldModule,
  InputModule,
  ButtonModule,
} from '@rlmoves/components';

import { HomeComponent } from './home.component';

@NgModule({
  declarations: [HomeComponent],
  exports: [HomeComponent],
  imports: [
    BrowserModule,
    ButtonModule,
    FormFieldModule,
    IconModule,
    InputModule,
    LogoModule,
    PopperModule,
    ReactiveFormsModule,
    RouterModule,
    ToolbarModule,
  ],
})
export class HomeModule {}
