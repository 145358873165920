import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-container-group',
  templateUrl: './container-group.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-container-group vs-16',
  },
})
export class ContainerGroupComponent {
  toggledSub = new Subject<string>();

  toggle(id: string): void {
    this.toggledSub.next(id);
  }

  getToggled(): Observable<string> {
    return this.toggledSub.asObservable();
  }
}
