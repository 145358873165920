import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactionDisplayComponent } from './reaction-display.component';
import { UserDisplayModule } from '../user-display';
import { IconModule } from '../icon';

@NgModule({
  declarations: [ReactionDisplayComponent],
  imports: [CommonModule, IconModule, UserDisplayModule],
  exports: [ReactionDisplayComponent],
})
export class ReactionDisplayModule {}
