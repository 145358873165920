import { ChangeDetectorRef, Directive, ElementRef } from '@angular/core';
import { BreakpointService, focusEnter, focusLeave } from '@rlmoves/core';
import { merge } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CanLeak } from '../mixins/can-leak';

@Directive({
  selector: '[appHiddenOverflow]',
  host: {
    class: 'o-none',
    '[class.o-auto]': 'show',
    '[class.app-overflow-show]': 'show',
    '[class.app-overflow-hide]': '!show',
    '[class.o-hidden]': '!show',
    '[class.c-pointer]': '!show',
    '[attr.tabindex]': '-1',
  },
})
export class HiddenOverflowDirective extends CanLeak {
  show: boolean;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointService: BreakpointService
  ) {
    super();
    this.subscribe();
  }

  private subscribe(): void {
    merge(
      focusEnter(this.elementRef.nativeElement).pipe(map(() => true)),
      focusLeave(this.elementRef.nativeElement).pipe(map(() => false))
    )
      .pipe(startWith(false), takeUntil(this.destroySub))
      .subscribe((show) => {
        if (this.breakpointService.mobileMatches()) {
          this.show = show;
        } else {
          this.show = true;
        }

        this.changeDetectorRef.markForCheck();

        if (!show) {
          this.elementRef.nativeElement.scrollTop = 0;
        }
      });
  }
}
