export type Reactions = Record<string, string>;

class AllReactions {
  [key: string]: string;
  '0' = 'Wow!';
  '1' = 'OMG!';
  '2' = 'No way!';
  '3' = 'Savage!';
  '4' = 'Siiiick!';
  '5' = 'Holy cow!';
  '6' = 'Nice shot!';
  '7' = 'Calculated.';
  '8' = 'Great pass!';
  '9' = 'Great clear!';
  '10' = 'Nice one!';
  '11' = 'What a play!';
  '12' = '$#@%!';
  '13' = 'Nice block!';
  '14' = 'Nice bump!';
  '15' = 'Nice demo!';
  '16' = 'Nice moves.';
  '17' = 'Well played.';
  '18' = 'That was fun!';
  '19' = 'Close one!';
}

export const ALL_REACTIONS = new AllReactions();

export const AVAILABLE_REACTIONS = Object.keys(ALL_REACTIONS).map((key) => ({
  id: key,
  value: ALL_REACTIONS[key],
}));

export interface UserReaction {
  userId: string;
  reaction: string;
}
