import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { PostUrl } from '@rlmoves/api';
import { postUrlToUrl } from '@rlmoves/core';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-external-player',
  templateUrl: './external-player.component.html',
  styleUrls: ['./external-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-external-player flex',
  },
})
export class ExternalPlayerComponent {
  @Input()
  set postUrl(value: PostUrl | null) {
    if (value) {
      this.postUrlSub.next(value);
    }
  }
  private postUrlSub = new ReplaySubject<PostUrl>(1);

  url$ = this.postUrlSub.pipe(map((postUrl) => postUrlToUrl(postUrl)));
}
