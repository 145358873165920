import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, merge } from 'rxjs';
import {
  map,
  switchMap,
  takeUntil,
  debounceTime,
  filter,
  withLatestFrom,
  tap,
  retry,
  pluck,
  startWith,
} from 'rxjs/operators';

import { AuthService, UserService } from '@rlmoves/api';

import { SignInInfo } from './login-model';
import { CanLeak } from '../mixins/can-leak';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-login',
  },
})
export class LoginComponent extends CanLeak {
  logInSub = new Subject<SignInInfo>();
  signUpSub = new Subject<SignInInfo>();

  auth$ = merge(
    this.logInSub.pipe(
      switchMap(() => this.authService.logIn(...this.formValue))
    ),
    this.signUpSub.pipe(
      switchMap(() => this.authService.signUp(...this.formValue))
    )
  ).pipe(
    tap({
      error: (error) => {
        this.formMessage = this.getErrorMessage(error);
        this.changeDetectorRef.markForCheck();
      },
    }),
    retry(Number.POSITIVE_INFINITY)
  );

  user$ = this.authService.getSignedInUser();
  nick$ = this.user$.pipe(map((user) => user && user.nick));
  likes$ = this.user$.pipe(map(user => user?.likes || 0), startWith(0));

  nickFormControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(16),
  ]);

  formGroup = new FormGroup({
    email: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
  });

  formMessage: string;

  get formValue(): [string, string] {
    return [this.formGroup.value.email, this.formGroup.value.password];
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.subscribe();
  }

  onLogIn(): void {
    if (this.formGroup.valid) {
      this.logInSub.next();
    }

    this.formGroup.markAsTouched();
  }

  onSignUp(): void {
    if (this.formGroup.valid) {
      this.signUpSub.next();
    }

    this.formGroup.markAsTouched();
  }

  onLogOut(): void {
    this.authService.logOut();
  }

  onResetPassword(): void {
    this.authService.sendPasswordResetEmail(this.formGroup.value.email);
    this.formMessage = `Sent reset email to ${this.formGroup.value.email}`;
  }

  private getErrorMessage(error: { code: string; message: string }): string {
    const { code, message } = error;

    if (code === 'auth/user-not-found') {
      return 'User not found';
    }

    if (code === 'auth/wrong-password') {
      return 'Invalid password';
    }

    return message;
  }

  private subscribe(): void {
    this.nick$
      .pipe(takeUntil(this.destroySub))
      .subscribe((nick) =>
        this.nickFormControl.setValue(nick, { emitEvent: false })
      );

    this.auth$.pipe(takeUntil(this.destroySub)).subscribe();

    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroySub))
      .subscribe(() => {
        this.formMessage = '';
        this.changeDetectorRef.markForCheck();
      });

    this.nickFormControl.valueChanges
      .pipe(
        withLatestFrom(this.user$),
        filter(() => this.nickFormControl.valid),
        debounceTime(5000),
        takeUntil(this.destroySub)
      )
      .subscribe(([nick, user]) => {
        if (user) {
          this.userService.update(user.id, { nick });
        }
      });
  }
}
