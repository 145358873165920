<svg viewBox="0 0 16 9"></svg>

<video
  class="app-border w-100"
  [poster]="previewUrl"
  controls
  autoplay
  loop
  muted
>
  <source [src]="gifUrl" [type]="type" />
</video>
