import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntersectionDirective } from './intersection.directive';
import { IntersectionNodeDirective } from './intersection-node.directive';

const API = [IntersectionNodeDirective, IntersectionDirective];

@NgModule({
  declarations: API,
  exports: API,
  imports: [CommonModule],
})
export class IntersectionModule {}
