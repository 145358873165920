<div
  class="h-100 w-100 app-reaction-available-reactions"
  *ngIf="availableReactions$ | async as availableReactions; else vote"
>
  <div
    class="c-pointer hoverable app-reaction-display-reaction"
    *ngFor="let reaction of availableReactions"
    (click)="onReaction(reaction.id)"
  >
    {{ reaction.value }}
  </div>
</div>

<ng-template #vote>
  <div class="app-reaction-display-reactions flex d-column h-100 w-100 vs-8">
    <div class="flex" *ngFor="let userReaction of userReactions$ | async">
      <div class="app-reaction-display-user app-secondary-text flex">
        <app-user-display [id]="userReaction.userId"></app-user-display>
        <div>:</div>
      </div>
      <div>{{ userReaction.reaction }}</div>
    </div>
  </div>

  <div class="app-reaction-display-vote flex d-column a-center">
    <app-icon
      [class.small-opaque]="noUpVote$ | async"
      [disabled]="isSignedOut$ | async"
      icon="up"
      (click)="onVote(true)"
    >
    </app-icon>

    <div class="app-reaction-display-count">{{ count$ | async }}</div>

    <app-icon
      [class.small-opaque]="noDownVote$ | async"
      [disabled]="isSignedOut$ | async"
      icon="down"
      (click)="onVote(false)"
    >
    </app-icon>
  </div>
</ng-template>
