import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';

import { PopperDirective } from './popper.directive';
import { PopperComponent } from './popper.component';

@NgModule({
  declarations: [PopperDirective, PopperComponent],
  exports: [PopperDirective, PopperComponent],
  imports: [CommonModule, OverlayModule, PortalModule],
})
export class PopperModule {}
