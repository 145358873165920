import { Injectable, Injector } from '@angular/core';
import { CrudApi } from 'angular-fire-utils';
import { Observable } from 'rxjs';

import { Post, PostQuery } from './post-model';

@Injectable({ providedIn: 'root' })
export class PostService extends CrudApi<Post> {
  constructor(protected injector: Injector) {
    super(injector, 'posts', 'session');
  }

  getPageByQuery(
    limit: number,
    postQuery: PostQuery,
    startAtId?: string | undefined
  ): Observable<Post[]> {
    return super.getPage(limit, startAtId, (query) =>
      query.orderByChild(postQuery.key).equalTo(postQuery.value)
    );
  }
}
