import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiddenOverflowDirective } from './hidden-overflow.directive';

@NgModule({
  declarations: [HiddenOverflowDirective],
  exports: [HiddenOverflowDirective],
  imports: [CommonModule],
})
export class HiddenOverflowModule {}
