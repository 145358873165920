<div class="flex j-space-between w-wrap w-100 s-4">
  <app-button-group [formControl]="categoryFormControl"
                    mode="radio">
    <button *ngFor="let category of categories"
            [value]="category"
            (click)="refresh(category)">
      {{category}}
    </button>
  </app-button-group>

  <div class="flex hs-16">
    <app-button-group [formControl]="difficultyFormControl">
      <button *ngFor="let difficulty of difficulties"
              [value]="difficulty">
        {{difficulty}}
      </button>
    </app-button-group>
  </div>
</div>

<div class="app-freestyle-content flex a-center j-center d-column vs-32 w-100"
     [ngSwitch]="selectedMode$ | async">
  <app-bingo-board *ngSwitchCase="'Bingo'"
                   [moves]="bingoMoves$ | async">
  </app-bingo-board>

  <ng-container *ngSwitchCase="'Horse'">
    <div class="app-freestyle-horse-item w-100 p-relative"
         *ngFor="let move of horseMoves$ | async; let last = last">
      <app-move-display class="h-100"
                        [move]="move"></app-move-display>

      <app-icon class="app-freestyle-arrow"
                *ngIf="!last"
                icon="curved_arrow">
      </app-icon>
    </div>
  </ng-container>
</div>