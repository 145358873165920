import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login.component';
import { ButtonModule } from '../button';
import { InputModule } from '../input/input.module';
import { FormFieldModule } from '../form-field';


@NgModule({
  declarations: [LoginComponent],
  imports: [
    ButtonModule,
    CommonModule,
    FormFieldModule,
    InputModule,
    ReactiveFormsModule
  ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule { }
