import { FactoryProvider, InjectionToken } from '@angular/core';
import {
  BALL,
  CAR_CONTROL,
  Category,
  CategoryOrMove,
  CONTROL,
  CONTROLS,
  DEFENCE,
  Difficulty,
  DRIBBLES,
  EASY,
  FLICKS,
  FLIPS,
  HARD,
  KICKOFFS,
  MODERATE,
  Move,
  MoveLink,
  MoveTransition,
  OFFENCE,
  PASSING,
  PINCHES,
  PRO,
  SHOTS,
} from '@rlmoves/api';
import { NavigatorTarget, NAVIGATOR_TARGETS } from '../navigator';
import { isIos } from '../util';
import { difficultyComparator, moveComparator, toSubMove } from '../util/moves';

export const MOVE = new InjectionToken<Move>('MOVE');

export const DRIVE = create('Drive', CONTROLS, CONTROL, 'drive');

export const STEER = create('Steer', CONTROLS, CONTROL, 'steer');

export const JUMP = create('Jump', CONTROLS, CONTROL, 'jump');

export const BOOST = create('Boost', CONTROLS, CONTROL, 'boost');

export const POWER_SLIDE = create(
  'Power Slide',
  CONTROLS,
  CONTROL,
  'power_slide'
);

export const AIR_ROLL = create('Air Roll', CONTROLS, CONTROL, 'air_roll');

export const AIR_STEER = create('Air Steer', CONTROLS, CONTROL, 'air_steer');

export const AIR_PITCH_UP = create(
  'Air Pitch Up',
  CONTROLS,
  CONTROL,
  'air_pitch_up'
);

export const AIR_PITCH_DOWN = create(
  'Air Pitch Down',
  CONTROLS,
  CONTROL,
  'air_pitch_down'
);

export const AIR_ROLL_LEFT_OR_RIGHT = create(
  'Air Roll LR',
  CONTROLS,
  CONTROL,
  'air_roll_lr'
);

export const BALL_ROLLING = create(
  'Ball Rolling',
  BALL,
  CONTROL,
  'ball_rolling'
);

export const BALL_FLYING = create('Ball Flying', BALL, CONTROL, 'ball_flying');

export const BALL_BOUNCING = create(
  'Ball Bouncing',
  BALL,
  CONTROL,
  'ball_bouncing'
);

export const BALL_DRIBBLING = create(
  'Ball Dribbling',
  BALL,
  CONTROL,
  'ball_dribbling'
);

export const BACKFLIP = create('Backflip', FLIPS, CONTROL, 'backflip', {
  subMoves: [[AIR_PITCH_UP, JUMP]],
});

export const FRONT_FLIP = create('Front Flip', FLIPS, CONTROL, 'front_flip', {
  subMoves: [[AIR_PITCH_DOWN, JUMP]],
});

export const CANCELLED_BACKFLIP = create(
  'Cancelled Backflip',
  FLIPS,
  CONTROL,
  'cancelled_backflip',
  { subMoves: [[BACKFLIP, AIR_PITCH_DOWN]] }
);

export const CANCELLED_FRONT_FLIP = create(
  'Cancelled Front Flip',
  FLIPS,
  CONTROL,
  'flip_cancel_shot',
  { subMoves: [[FRONT_FLIP, AIR_PITCH_UP]] }
);

export const SIDE_FLIP = create('Side Flip', FLIPS, CONTROL, 'side_flip', {
  subMoves: [[AIR_STEER, JUMP]],
});

export const DIAGONAL_FLIP = create(
  'Diagonal Flip',
  FLIPS,
  CONTROL,
  'diagonal_flip',
  { subMoves: [[AIR_STEER, AIR_PITCH_DOWN], JUMP] }
);

export const WAVEDASH = create('Wavedash', FLIPS, MODERATE, 'wavedash', {
  subMoves: [AIR_PITCH_UP, FRONT_FLIP],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=qG-ZK2ocAzw'),
    createTrainingPackLink('F9EF-2D99-BA51-9E8A'),
  ],
  transition: { from: 'ground', to: 'ground' },
  scorable: true,
  assistable: true,
});

export const CURVEDASH = create('Curvedash', FLIPS, HARD, 'curvedash', {
  subMoves: [JUMP, WAVEDASH],
  links: [createYoutubeLink('https://www.youtube.com/watch?v=PuB6yLVSs5s')],
  transition: { from: 'wall', to: 'ground' },
  scorable: true,
  assistable: true,
});

export const CHAIN_DASH = create('Chain Dash', FLIPS, HARD, 'chain_dash', {
  subMoves: [
    WAVEDASH,
    AIR_ROLL_LEFT_OR_RIGHT,
    WAVEDASH,
    AIR_ROLL_LEFT_OR_RIGHT,
  ],
  links: [createYoutubeLink('https://www.youtube.com/watch?v=_RGqBD3oUmk')],
  transition: { from: 'wall', to: 'wall' },
  scorable: true,
});

export const HEL_JUMP = create('Hel Jump', FLIPS, PRO, 'hel_jump', {
  subMoves: [JUMP, AIR_PITCH_DOWN, JUMP, AIR_PITCH_UP],
  links: [createYoutubeLink('https://www.youtube.com/watch?v=p1KxjeQT5As')],
  transition: { from: 'ground', to: 'air' },
  scorable: true,
});

export const HALF_FLIP = create('Half Flip', FLIPS, MODERATE, 'half_flip', {
  subMoves: [[CANCELLED_BACKFLIP, AIR_ROLL_LEFT_OR_RIGHT]],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=PioMaTXS2WI'),
    createTrainingPackLink('00D6-B194-DFC9-797A'),
  ],
  transition: { from: 'ground', to: 'ground' },
  scorable: true,
  assistable: true,
});

export const SPEED_FLIP = create('Speed Flip', FLIPS, HARD, 'speed_flip', {
  subMoves: [[DRIVE, BOOST, CANCELLED_FRONT_FLIP], AIR_ROLL],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=W0494Cf6WCU'),
    createTrainingPackLink('A503-264C-A7EB-D282'),
  ],
});

export const SONIC_FLIP = create('Sonic Flip', FLIPS, PRO, 'sonic_flip', {
  subMoves: [SPEED_FLIP, WAVEDASH],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=CuNA-vIdpWE'),
    createTrainingPackLink('A503-264C-A7EB-D282'),
  ],
  transition: { from: 'ground', to: 'ground' },
  scorable: true,
  assistable: true,
});

export const STALL = create('Stall', FLIPS, PRO, 'stall', {
  subMoves: [[SIDE_FLIP, AIR_ROLL_LEFT_OR_RIGHT]],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=jASrhGw4QYU'),
    createTrainingPackLink('FFC1-8E07-CA29-463A'),
  ],
  assistable: true,
});

export const AERIAL = create('Aerial', CAR_CONTROL, MODERATE, 'aerial', {
  subMoves: [[AIR_PITCH_UP, JUMP, BOOST]],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=3YtxID9OgRQ'),
    createTrainingPackLink('C7E0-9E0B-B739-A899'),
  ],
  transition: { from: 'air', to: 'goal' },
  scorable: true,
  assistable: true,
});

export const FAST_AERIAL = create(
  'Fast Aerial',
  CAR_CONTROL,
  MODERATE,
  'fast_aerial',
  {
    subMoves: [AERIAL, AIR_PITCH_UP, JUMP],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=nn78yEa_XgM'),
      createTrainingPackLink('2BF7-DC0C-3D77-3B5E'),
    ],
    transition: { from: 'ground', to: 'air' },
    scorable: true,
    assistable: true,
  }
);

export const TORNADO_SPIN = create(
  'Tornado Spin',
  CAR_CONTROL,
  MODERATE,
  'tornado_spin',
  {
    subMoves: [AERIAL, [AIR_ROLL_LEFT_OR_RIGHT, AIR_STEER]],
    links: [createYoutubeLink('https://youtu.be/ZydFn57y_bs?t=187')],
    scorable: true,
    assistable: true,
  }
);

export const DOUBLE_JUMP = create(
  'Double Jump',
  CAR_CONTROL,
  CONTROL,
  'double_jump',
  {
    subMoves: [JUMP, JUMP],
  }
);

export const CEILING_RESET = create(
  'Ceiling Reset',
  CAR_CONTROL,
  HARD,
  'ceiling_shot',
  {
    subMoves: [AERIAL, AIR_ROLL_LEFT_OR_RIGHT, DRIVE],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=PkIupX66sF8'),
      createTrainingPackLink('94D7-0D24-CCDF-7A7A'),
    ],
    scorable: true,
    assistable: true,
  }
);

export const POGO = create('Pogo', CAR_CONTROL, PRO, 'pogo', {
  scorable: true,
  assistable: true,
  links: [createYoutubeLink('https://www.youtube.com/watch?v=UDVdWV2NZJk')],
});

export const FLIP_RESET = create('Flip Reset', CAR_CONTROL, PRO, 'flip_reset', {
  subMoves: [BALL_FLYING, AERIAL, [AIR_PITCH_UP, AIR_ROLL_LEFT_OR_RIGHT]],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=kDhYkOlXrxM&t=24s'),
    createTrainingPackLink('2186-5167-B7C8-C46F'),
  ],
  transition: { from: 'air', to: 'air' },
  scorable: true,
  assistable: true,
});

export const RECOVERY = create('Recovery', CAR_CONTROL, MODERATE, 'recovery', {
  subMoves: [[POWER_SLIDE, STEER]],
  links: [
    createYoutubeLink('https://youtu.be/EapIkKuu_6M?t=560'),
    createTrainingPackLink('E6A3-2580-2D62-431E'),
  ],
  teamOnly: true,
});

export const BACK_PASS = create('Back Pass', PASSING, EASY, 'back_pass', {
  teamOnly: true,
  scorable: true,
});

export const BACKBOARD_PASS = create(
  'Backboard Pass',
  PASSING,
  EASY,
  'backboard_pass',
  { teamOnly: true, scorable: true }
);

export const INFIELD_PASS = create(
  'Infield Pass',
  PASSING,
  MODERATE,
  'infield_pass',
  { teamOnly: true, scorable: true }
);

export const CORNER_PASS = create('Corner Pass', PASSING, EASY, 'corner_pass', {
  teamOnly: true,
  scorable: true,
});

export const POP_PASS = create('Pop Pass', PASSING, MODERATE, 'pop_pass', {
  teamOnly: true,
  scorable: true,
});

export const GUILLOTINE_PASS = create(
  'Guillotine Pass',
  PASSING,
  MODERATE,
  'guillotine_pass',
  { teamOnly: true, scorable: true }
);

export const SHOT = create('Shot', SHOTS, EASY, 'shot', {
  links: [createTrainingPackLink('6EB1-79B2-33B8-681C')],
  scorable: true,
});

export const AIR_ROLL_SHOT = create(
  'Air Roll Shot',
  SHOTS,
  MODERATE,
  'air_roll_shot',
  {
    subMoves: [AIR_ROLL, SHOT],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=fuHDGGrDAzI'),
      createTrainingPackLink('B16C-8FDA-D26C-32FD'),
    ],
    transition: { from: 'air', to: 'finish' },
    scorable: true,
  }
);

export const DOOMSEE_DISH = create(
  'Doomsee Dish',
  SHOTS,
  HARD,
  'doomsee_dish',
  {
    subMoves: [BALL_DRIBBLING, AIR_ROLL_LEFT_OR_RIGHT, SIDE_FLIP],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=0gk8YzgOdMc'),
      createTrainingPackLink('8060-7324-DCF5-CAE7'),
    ],
    transition: { from: 'wall', to: 'finish' },
    scorable: true,
  }
);

export const REDIRECT = create('Redirect', SHOTS, PRO, 'redirect', {
  subMoves: [AERIAL, SHOT],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=0yrJisRrqdo'),
    createTrainingPackLink('F22D-2A16-68BA-C6C7'),
  ],
  transition: { from: 'air', to: 'finish' },
  scorable: true,
  assistable: true,
});

export const DOUBLE_TAP = create('Double Tap', SHOTS, HARD, 'double_tap', {
  subMoves: [AERIAL, BACK_PASS, SHOT],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=IFrefYRu9MY'),
    createTrainingPackLink('D7F8-FD53-98D1-DAFE'),
  ],
  transition: { from: 'goal', to: 'finish' },
  scorable: true,
});

export const WALL_CLEAR = create('Wall Clear', SHOTS, HARD, 'wall_clear', {
  subMoves: [DRIVE, SIDE_FLIP],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=ApfXqgJEjVk'),
    createTrainingPackLink('89F5-2C75-3B79-BA52'),
  ],
  scorable: true,
  assistable: true,
});

export const POP_SHOT = create('Pop Shot', SHOTS, EASY, 'pop_shot', {
  subMoves: [[BALL_BOUNCING, SHOT]],
  scorable: true,
});

export const PREFLIP_SHOT = create('Preflip Shot', SHOTS, PRO, 'preflip_shot', {
  subMoves: [DIAGONAL_FLIP, SHOT],
  scorable: true,
});

export const DOUBLE_TOUCH = create(
  'Double Touch',
  SHOTS,
  HARD,
  'double_touch',
  {
    subMoves: [SHOT, SHOT],
    scorable: true,
  }
);

export const POWER_SHOT = create('Power Shot', SHOTS, EASY, 'power_shot', {
  subMoves: [FRONT_FLIP, SHOT],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=-TSOvHj8s2U'),
    createTrainingPackLink('D345-E535-B672-D7DD'),
  ],
  transition: { from: 'ground', to: 'goal' },
  scorable: true,
});

export const FLIP_CANCEL_SHOT = create(
  'Flip Cancel Shot',
  SHOTS,
  HARD,
  'flip_cancel_shot',
  {
    subMoves: [CANCELLED_FRONT_FLIP, SHOT],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=d3G_v2k1m7U'),
      createTrainingPackLink('D345-E535-B672-D7DD'),
    ],
    scorable: true,
  }
);

export const BOUNCE_SHOT = create(
  'Bounce Shot',
  SHOTS,
  MODERATE,
  'bounce_shot',
  {
    subMoves: [BALL_BOUNCING, STEER, SIDE_FLIP, SHOT],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=fA0ivgr69Xg'),
      createTrainingPackLink('D345-E535-B672-D7DD'),
    ],
    scorable: true,
  }
);

export const HOOK_SHOT = create('Hook Shot', SHOTS, MODERATE, 'hook_shot', {
  subMoves: [BALL_ROLLING, STEER, SHOT],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=2X65mgR9Nbo'),
    createTrainingPackLink('6EB1-79B2-33B8-681C'),
  ],
  scorable: true,
});

export const BACKFLIP_SHOT = create(
  'Backflip Shot',
  SHOTS,
  MODERATE,
  'backflip_shot',
  {
    subMoves: [BALL_BOUNCING, [BACKFLIP, SHOT]],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=fA0ivgr69Xg'),
      createTrainingPackLink('D345-E535-B672-D7DD'),
    ],
    scorable: true,
  }
);

export const CEILING_SHOT = create('Ceiling Shot', SHOTS, PRO, 'ceiling_shot', {
  subMoves: [BALL_FLYING, CEILING_RESET, SHOT],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=PkIupX66sF8'),
    createTrainingPackLink('94D7-0D24-CCDF-7A7A'),
  ],
  transition: { from: 'wall', to: 'goal' },
  scorable: true,
});

export const GOAL = create('Goal', SHOTS, EASY, 'goal');

export const DRIBBLE = create('Dribble', DRIBBLES, EASY, 'dribble', {
  subMoves: [[BALL_DRIBBLING, DRIVE]],
  links: [
    createYoutubeLink('https://youtu.be/EapIkKuu_6M?t=223'),
    createTrainingPackLink('BFF5-2F90-5E7C-B9CD'),
  ],
  transition: { from: 'ground', to: 'ground' },
  scorable: true,
});

export const POP = create('Pop', FLICKS, EASY, 'pop', {
  subMoves: [DRIBBLE, JUMP],
  links: [
    createYoutubeLink('https://youtu.be/ZWdheraunb4'),
    createTrainingPackLink('4A6D-8E15-6071-2D61'),
  ],
  scorable: true,
});

export const BOUNCE_DRIBBLE = create(
  'Bounce Dribble',
  DRIBBLES,
  HARD,
  'bounce_dribble',
  {
    subMoves: [BALL_BOUNCING, POP_SHOT, BALL_BOUNCING, POP_SHOT],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=fA0ivgr69Xg'),
      createTrainingPackLink('BFF5-2F90-5E7C-B9CD'),
    ],
    scorable: true,
  }
);

export const AIR_DRIBBLE = create(
  'Air Dribble',
  DRIBBLES,
  HARD,
  'air_dribble',
  {
    subMoves: [[AERIAL, BALL_DRIBBLING]],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=xfuT90V4jKY'),
      createTrainingPackLink('5A65-4073-F310-5495'),
    ],
    transition: { from: 'air', to: 'air' },
    scorable: true,
  }
);

export const WALL_AIR_DRIBBLE = create(
  'Wall To Air Dribble',
  DRIBBLES,
  HARD,
  'wall_air_dribble',
  {
    subMoves: [SHOT, AIR_DRIBBLE],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=xfuT90V4jKY'),
      createTrainingPackLink('5A65-4073-F310-5495'),
    ],
    transition: { from: 'wall', to: 'air' },
    scorable: true,
  }
);

export const DRIBBLE_AIR_DRIBBLE = create(
  'Dribble To Air Dribble',
  DRIBBLES,
  PRO,
  'dribble_air_dribble',
  {
    subMoves: [POP, AIR_DRIBBLE],
    links: [
      createYoutubeLink('https://youtu.be/gUQyeevDAKU'),
      createTrainingPackLink('BFF5-2F90-5E7C-B9CD'),
    ],
    transition: { from: 'ground', to: 'air' },
    scorable: true,
  }
);

export const WAVEDASH_DRIBBLE = create(
  'Wavedash Dribble',
  DRIBBLES,
  PRO,
  'wavedash_dribble',
  {
    subMoves: [[WAVEDASH, POWER_SLIDE, BALL_DRIBBLING]],
    links: [createTrainingPackLink('BFF5-2F90-5E7C-B9CD')],
    transition: { from: 'ground', to: 'ground' },
    scorable: true,
  }
);

export const POWERSLIDE_DRIBBLE = create(
  'Powerslide Dribble',
  DRIBBLES,
  HARD,
  'power_slide_dribble',
  {
    subMoves: [[POWER_SLIDE, BALL_DRIBBLING]],
    links: [createTrainingPackLink('BFF5-2F90-5E7C-B9CD')],
    transition: { from: 'ground', to: 'ground' },
    scorable: true,
  }
);

export const POP_AIR_DRIBBLE = create(
  'Pop To Air Dribble',
  DRIBBLES,
  HARD,
  'pop_air_dribble',
  {
    subMoves: [POP_SHOT, AIR_DRIBBLE],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=XM9nUGMuBs8'),
      createTrainingPackLink('22C6-636F-E52C-D9E7'),
    ],
    transition: { from: 'ground', to: 'air' },
    scorable: true,
  }
);

export const FRONT_FLICK = create('Front Flick', FLICKS, EASY, 'front_flick', {
  subMoves: [POP, FRONT_FLIP],
  links: [
    createYoutubeLink('https://youtu.be/ZWdheraunb4?t=574'),
    createTrainingPackLink('4A6D-8E15-6071-2D61'),
  ],
  transition: { from: 'ground', to: 'goal' },
  scorable: true,
  assistable: true,
});

export const DIAGONAL_FLICK = create(
  'Diagonal Flick',
  FLICKS,
  MODERATE,
  'diagonal_flick',
  {
    subMoves: [POP, DIAGONAL_FLIP],
    links: [
      createYoutubeLink('https://youtu.be/ZWdheraunb4?t=580'),
      createTrainingPackLink('4A6D-8E15-6071-2D61'),
    ],
    transition: { from: 'ground', to: 'goal' },
    scorable: true,
    assistable: true,
  }
);

export const SIDE_FLICK = create('Side Flick', FLICKS, MODERATE, 'side_flick', {
  subMoves: [POP, SIDE_FLIP],
  links: [
    createYoutubeLink('https://youtu.be/ZWdheraunb4?t=595'),
    createTrainingPackLink('4A6D-8E15-6071-2D61'),
  ],
  transition: { from: 'ground', to: 'goal' },
  scorable: true,
  assistable: true,
});

export const BACK_FLICK = create('Back Flick', FLICKS, MODERATE, 'back_flick', {
  subMoves: [POP, BACKFLIP],
  links: [
    createYoutubeLink('https://youtu.be/ZWdheraunb4?t=586'),
    createTrainingPackLink('4A6D-8E15-6071-2D61'),
  ],
  transition: { from: 'ground', to: 'air' },
  scorable: true,
  assistable: true,
});

export const FORTYFIVE_FLICK = create(
  '45 Flick',
  FLICKS,
  HARD,
  'fortyfive_flick',
  {
    subMoves: [
      POP,
      [AIR_STEER, AIR_ROLL_LEFT_OR_RIGHT],
      [DIAGONAL_FLIP, BACKFLIP],
    ],
    links: [
      createYoutubeLink('https://youtu.be/ZWdheraunb4?t=637'),
      createTrainingPackLink('4A6D-8E15-6071-2D61'),
    ],
    transition: { from: 'ground', to: 'goal' },
    scorable: true,
    assistable: true,
  }
);

export const NINETY_FLICK = create('90 Flick', FLICKS, HARD, 'ninety_flick', {
  subMoves: [POP, [AIR_STEER, AIR_ROLL_LEFT_OR_RIGHT], SIDE_FLIP],
  links: [
    createYoutubeLink('https://youtu.be/ZWdheraunb4?t=645'),
    createTrainingPackLink('4A6D-8E15-6071-2D61'),
  ],
  transition: { from: 'ground', to: 'goal' },
  scorable: true,
  assistable: true,
});

export const ONE_EIGHTY_FLICK = create(
  '180 Flick',
  FLICKS,
  HARD,
  'one_eighty_flick',
  {
    subMoves: [POP, AIR_STEER, BACKFLIP],
    links: [
      createYoutubeLink('https://youtu.be/ZWdheraunb4?t=660'),
      createTrainingPackLink('4A6D-8E15-6071-2D61'),
    ],
    transition: { from: 'ground', to: 'goal' },
    scorable: true,
    assistable: true,
  }
);

export const AIR_ROLL_FLICK = create(
  'Air Roll Flick',
  FLICKS,
  PRO,
  'air_roll_flick',
  {
    subMoves: [POP, AIR_ROLL_LEFT_OR_RIGHT, BACKFLIP],
    links: [createTrainingPackLink('4A6D-8E15-6071-2D61')],
    transition: { from: 'ground', to: 'finish' },
    scorable: true,
    assistable: true,
  }
);

export const MUSTY_FLICK = create('Musty Flick', FLICKS, PRO, 'musty_flick', {
  subMoves: [AIR_PITCH_DOWN, BACKFLIP],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=96tNxK5vTsQ'),
    createTrainingPackLink('D87A-9A7A-DBD9-78AF'),
  ],
  transition: { from: 'air', to: 'goal' },
  scorable: true,
  assistable: true,
});

export const BREEZI_FLICK = create(
  'Breezi Flick',
  FLICKS,
  PRO,
  'breezi_flick',
  {
    subMoves: [TORNADO_SPIN, MUSTY_FLICK],
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=ZydFn57y_bs'),
      createTrainingPackLink('D87A-9A7A-DBD9-78AF'),
    ],
    transition: { from: 'air', to: 'goal' },
    scorable: true,
    assistable: true,
  }
);

export const GROUND_PINCH = create(
  'Ground Pinch',
  PINCHES,
  PRO,
  'ground_pinch',
  {
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=DNpOwlhBnYA'),
      createTrainingPackLink(' 21C6-0DC1-233D-EEF7'),
    ],
    transition: { from: 'air', to: 'finish' },
    scorable: true,
  }
);

export const WALL_PINCH = create('Wall Pinch', PINCHES, HARD, 'wall_pinch', {
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=n9_JFybKlw0'),
    createTrainingPackLink('B746-04D7-43F8-CA51'),
  ],
  transition: { from: 'ground', to: 'finish' },
  scorable: true,
});

export const KUXIR_PINCH = create('Kuxir Pinch', PINCHES, HARD, 'kuxir_pinch', {
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=-2qofsie8t0'),
    createTrainingPackLink('0E99-5226-5F68-4804'),
  ],
  transition: { from: 'ground', to: 'finish' },
  scorable: true,
});

export const TEAM_PINCH = create('Team Pinch', PINCHES, PRO, 'team_pinch', {
  links: [createYoutubeLink('https://www.youtube.com/watch?v=GFk_Scf-rpE')],
  teamOnly: true,
  scorable: true,
});

export const TEAM_AIR_PINCH = create(
  'Team Air Pinch',
  PINCHES,
  PRO,
  'team_air_pinch',
  {
    links: [createYoutubeLink('https://www.youtube.com/watch?v=GFk_Scf-rpE')],
    teamOnly: true,
    scorable: true,
  }
);

export const CEILING_PINCH = create(
  'Ceiling Pinch',
  PINCHES,
  PRO,
  'ceiling_pinch',
  {
    links: [
      createYoutubeLink('https://www.youtube.com/watch?v=omrhnMm0xrw'),
      createTrainingPackLink('A6B8-D819-A48D-DDB2'),
    ],
    transition: { from: 'wall', to: 'finish' },
    scorable: true,
  }
);

export const POST_PINCH = create('Post Pinch', PINCHES, PRO, 'post_pinch', {
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=VROD6G711hw'),
    createTrainingPackLink('7DBE-2AA5-82EA-706B'),
  ],
  transition: { from: 'ground', to: 'finish' },
  scorable: true,
});

export const FIFTY_FIFTY = create('Fifty Fifty', OFFENCE, EASY, 'fifty_fifty', {
  links: [createYoutubeLink('https://www.youtube.com/watch?v=YDXxcW-novQ')],
  teamOnly: true,
  scorable: true,
  assistable: true,
});

export const DUNK = create('Dunk', OFFENCE, EASY, 'dunk', {
  links: [createYoutubeLink('https://www.youtube.com/watch?v=YDXxcW-novQ')],
  teamOnly: true,
});

export const BLOCK = create('Block', OFFENCE, EASY, 'block', {
  links: [createYoutubeLink('https://www.youtube.com/watch?v=YDXxcW-novQ')],
  teamOnly: true,
  scorable: true,
  assistable: true,
});

export const TRIPPING = create('Tripping', OFFENCE, EASY, 'tripping', {
  teamOnly: true,
});

export const BUMP = create('Bump', OFFENCE, EASY, 'bump', {
  teamOnly: true,
  scorable: true,
  assistable: true,
});

export const AIR_DRIBBLE_BUMP = create(
  'Air Dribble Bump',
  OFFENCE,
  PRO,
  'pop_air_dribble',
  {
    links: [createYoutubeLink('https://www.youtube.com/watch?v=XIG84V6ERCA')],
    transition: { from: 'air', to: 'finish' },
    teamOnly: true,
    scorable: true,
  }
);

export const DEMOLITION = create('Demolition', OFFENCE, EASY, 'demolition', {
  links: [createYoutubeLink('https://www.youtube.com/watch?v=J0W6iyqhtHw')],
  teamOnly: true,
  scorable: true,
});

export const FAKE = create('Fake', OFFENCE, EASY, 'fake', {
  links: [createYoutubeLink('https://www.youtube.com/watch?v=qnsSS2Pg3YI')],
  teamOnly: true,
  scorable: true,
});

export const SAVE = create('Save', DEFENCE, EASY, 'save', {
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=vl5CojF2wDs'),
    createTrainingPackLink('2E23-ABD5-20C6-DBD4'),
  ],
  teamOnly: true,
});

export const REDIRECT_SAVE = create(
  'Redirect Save',
  DEFENCE,
  MODERATE,
  'redirect_save',
  { teamOnly: true }
);

export const SOFT_BLOCK = create('Soft Block', DEFENCE, HARD, 'soft_block', {
  teamOnly: true,
  transition: { from: 'air', to: 'ground' },
});

export const CEILING_SAVE = create(
  'Ceiling Save',
  DEFENCE,
  HARD,
  'ceiling_save',
  {
    links: [createYoutubeLink('https://www.youtube.com/watch?v=sZHBtXg23Cg')],
    teamOnly: true,
  }
);

export const KICKOFF = create('Kickoff', KICKOFFS, EASY, 'kickoff', {
  subMoves: [SPEED_FLIP, FIFTY_FIFTY],
  links: [
    createYoutubeLink('https://www.youtube.com/watch?v=hKMnJn04LlY'),
    createTrainingPackLink('A503-264C-A7EB-D282'),
  ],
  teamOnly: true,
  scorable: true,
});

export const WAVEDASH_KICKOFF = create(
  'Wavedash Kickoff',
  KICKOFFS,
  PRO,
  'wavedash_kickoff',
  {
    subMoves: [KICKOFF, WAVEDASH],
    links: [createYoutubeLink('https://www.youtube.com/watch?v=EoTCU1fwBAU')],
    teamOnly: true,
    scorable: true,
  }
);

export const REDIRECT_KICKOFF = create(
  'Redirect Kickoff',
  KICKOFFS,
  PRO,
  'redirect_kickoff',
  {
    subMoves: [KICKOFF, REDIRECT],
    links: [createYoutubeLink('https://www.youtube.com/watch?v=hKMnJn04LlY')],
    teamOnly: true,
    assistable: true,
  }
);

export const FRIENDSHIP_KICKOFF = create(
  'Friendship Kickoff',
  KICKOFFS,
  PRO,
  'friendship_kickoff',
  {
    subMoves: [BUMP, KICKOFF],
    teamOnly: true,
    extraMode: true,
  }
);

type MoveProvider = { useValue: Move; provide: any; multi: true };

function createYoutubeLink(url: string): MoveLink {
  return {
    icon: 'youtube',
    tooltip: 'Watch tutorial',
    url,
  };
}

function createTrainingPackLink(clipboard: string): MoveLink {
  return {
    icon: 'copy',
    tooltip: 'Copy training pack',
    clipboard,
    hideOnMobile: true,
  };
}

function create(
  name: string,
  category: Category,
  difficulty: Difficulty,
  fileName: string,
  optionals?: {
    subMoves?: (MoveProvider | MoveProvider[])[];
    links?: MoveLink[];
    transition?: MoveTransition;
    teamOnly?: boolean;
    extraMode?: boolean;
    scorable?: boolean;
    assistable?: boolean;
  }
): MoveProvider {
  return {
    provide: MOVE,
    useValue: {
      name,
      category,
      difficulty,
      fileName: fileName,
      gifUrl: `/assets/gif/${fileName}${isIos() ? '.mp4' : '.webm'}`,
      previewUrl: `/assets/gif/${fileName}.jpeg`,
      links: optionals?.links ?? [],
      teamOnly: optionals?.teamOnly,
      extraMode: optionals?.extraMode,
      scorable: optionals?.scorable,
      assistable: optionals?.assistable,
      transition: optionals?.transition,
      subMoves: toSubMove(
        optionals?.subMoves?.map((subMove) =>
          Array.isArray(subMove)
            ? subMove.map((s) => s.useValue)
            : subMove.useValue
        ) ?? []
      ),
    },
    multi: true,
  };
}

export function categoriesOrMovesFactory(moves: Move[]): CategoryOrMove[] {
  return moves
    .sort(difficultyComparator)
    .sort(moveComparator)
    .reduce((acc, move, index, source) => {
      if (!source[index - 1] || source[index - 1].category !== move.category) {
        acc.push(move.category);
      }

      acc.push(move);
      return acc;
    }, [] as CategoryOrMove[]);
}

export const CATEGORIES_AND_MOVES = new InjectionToken<CategoryOrMove[]>(
  'CATEGORIES_AND_MOVES'
);

export const CATEGORIES_AND_MOVES_PROVIDER: FactoryProvider = {
  provide: CATEGORIES_AND_MOVES,
  useFactory: categoriesOrMovesFactory,
  deps: [MOVE],
};

export function navigatorTargetsFactory(
  categoriesAndMoves: CategoryOrMove[]
): NavigatorTarget[] {
  return categoriesAndMoves.map((categoryOrMove) => ({
    url: `/wiki/${
      typeof categoryOrMove === 'string'
        ? categoryOrMove
        : categoryOrMove.fileName
    }`,
    match:
      typeof categoryOrMove === 'string' ? categoryOrMove : categoryOrMove.name,
  }));
}

export const NAVIGATOR_TARGETS_PROVIDER: FactoryProvider = {
  provide: NAVIGATOR_TARGETS,
  useFactory: navigatorTargetsFactory,
  deps: [CATEGORIES_AND_MOVES],
};
