import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { ContainerCollapsedBase } from './container-collapsed-base';
import { ContainerGroupComponent } from './container-group.component';

@Component({
  selector: 'app-container-group-toggle',
  templateUrl: './container-group-toggle.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(click)': 'onClick()',
    '[class.medium-opaque]': 'collapsed',
    '[class.pe-none]': 'disabled',
  },
})
export class ContainerGroupToggleComponent extends ContainerCollapsedBase {
  @Input() disabled: boolean | null;

  constructor(
    protected containerGroupComponent: ContainerGroupComponent,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(containerGroupComponent, changeDetectorRef);
  }

  onClick(): void {
    if (this.id) {
      this.containerGroupComponent.toggle(this.id);
    }
  }
}
