import { DIFFICULTIES } from '@rlmoves/api';
import { POP_SHOT, RECOVERY, SHOT, SPEED_FLIP, TORNADO_SPIN } from '@rlmoves/core';

export type FreestyleCategory = 'Bingo' | 'Horse';

export const DEFAULT_FREESTYLE_CATEGORY: FreestyleCategory = 'Bingo';

export const FREESTYLE_CATEGORIES: FreestyleCategory[] = ['Bingo', 'Horse'];

export const FREESTYLE_TEAM_FLAG = 'Team';

export const FREESTYE_DIFFICULTIES = [
  FREESTYLE_TEAM_FLAG,
  ...DIFFICULTIES.filter((difficulty) => difficulty !== 'Control'),
];

export const FORBIDDEN_FREESTYLE_MOVES = [
  SHOT.useValue,
  POP_SHOT.useValue,
  TORNADO_SPIN.useValue,
  SPEED_FLIP.useValue,
  RECOVERY.useValue,
];
