import {
  Observable,
  fromEvent,
  merge,
  MonoTypeOperatorFunction,
  asapScheduler,
} from 'rxjs';
import {
  map,
  takeUntil,
  switchMap,
  debounceTime,
  take,
  distinctUntilChanged,
  filter,
  throttleTime,
} from 'rxjs/operators';

export function mouseStationary(
  element: HTMLElement,
  debounce: number = 250
): Observable<MouseEvent> {
  const mousemove$ = fromEvent<MouseEvent>(element, 'mousemove');
  const mouseleave$ = fromEvent<MouseEvent>(element, 'mouseleave');
  const mouseenter$ = fromEvent<MouseEvent>(element, 'mouseenter');
  const click$ = fromEvent<MouseEvent>(element, 'click');

  return mouseenter$.pipe(
    switchMap((mouseenterEvent) => {
      return mousemove$.pipe(
        debounceTime(debounce),
        map(() => mouseenterEvent),
        take(1),
        takeUntil(merge(mouseleave$, click$))
      );
    })
  );
}

export function deepDistinctUntilChanged<T>(): MonoTypeOperatorFunction<T> {
  return (source$) => source$.pipe(distinctUntilChanged(isEqual));
}

function isEqual(a: any, b: any) {
  if (typeof a == 'object' && a != null && typeof b == 'object' && b != null) {
    const count = [0, 0];

    for (let _ in a) count[0]++;
    for (let _ in b) count[1]++;

    if (count[0] - count[1] != 0) {
      return false;
    }

    for (const key in a) {
      if (!(key in b) || !isEqual(a[key], b[key])) {
        return false;
      }
    }

    for (const key in b) {
      if (!(key in a) || !isEqual(b[key], a[key])) {
        return false;
      }
    }

    return true;
  } else {
    return a === b;
  }
}

export function focusEnter(elem: HTMLElement): Observable<FocusEvent> {
  const focusin$ = fromEvent<FocusEvent>(elem, 'focusin');
  const focusout$ = fromEvent<FocusEvent>(elem, 'focusout');

  return merge(focusin$, focusout$).pipe(
    throttleTime(0, asapScheduler),
    filter((event) => event.type === 'focusin')
  );
}

export function focusLeave(elem: HTMLElement): Observable<FocusEvent> {
  const focusin$ = fromEvent<FocusEvent>(elem, 'focusin');
  const focusout$ = fromEvent<FocusEvent>(elem, 'focusout');

  return merge(focusin$, focusout$).pipe(
    debounceTime(0, asapScheduler),
    filter((event) => event.type === 'focusout')
  );
}
