import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { ContainerCollapsedBase } from './container-collapsed-base';
import { ContainerGroupComponent } from './container-group.component';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-container',
    '[class.d-none]': 'collapsed',
    '[class.app-container-vertical]': 'vertical',
    '[class.app-container-absolute-title]': 'absoluteTitle',
  },
})
export class ContainerComponent extends ContainerCollapsedBase {
  @Input() vertical: boolean;
  @Input() absoluteTitle: boolean;

  constructor(
    @Optional() protected containerGroupComponent: ContainerGroupComponent,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(containerGroupComponent, changeDetectorRef);
  }
}
