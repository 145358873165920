import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Injector,
  OnDestroy,
} from '@angular/core';
import { Location } from '@angular/common';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';

import { ThemeService } from '@rlmoves/core';
import { Move } from '@rlmoves/api';

import { GifPlayerFullComponent } from './gif-player-full.component';
import { GifPlayer } from './gif-player-model';
import { SeoService } from 'angular-fire-utils';

@Component({
  selector: 'app-gif-player',
  templateUrl: './gif-player.component.html',
  styleUrls: ['./gif-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-gif-player app-border c-pointer flex j-flex-end d-column',
    '[class.app-gif-player-compact]': 'compact',
    '(click)': 'show(); navigateToGif()',
  },
})
export class GifPlayerComponent extends GifPlayer implements OnDestroy {
  isDark$ = this.themeService.getDark();

  @Input() move: Move;

  @Input() compact: boolean | null;

  get previewUrl() {
    return this.move?.previewUrl;
  }

  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private location: Location,
    private themeService: ThemeService,
    private seoService: SeoService
  ) {
    super();
  }

  navigateToGif(): void {
    this.location.replaceState(this.getMoveUrl());
  }

  navigateFromGif(): void {
    this.location.replaceState('/wiki');
  }

  show(): void {
    const portal = new ComponentPortal(
      GifPlayerFullComponent,
      undefined,
      this.createInjector()
    );

    this.overlayRef = this.createOverlay();

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.overlayRef.detachments()))
      .subscribe(() => this.close());

    this.overlayRef.attach(portal);

    this.seo();
  }

  close(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.navigateFromGif();
      this.seoService.setTags({ title: 'rlmoves - Wiki' });
    }
  }

  private openInitially(): void {
    if (this.location.isCurrentPathEqualTo(encodeURI(this.getMoveUrl()))) {
      this.show();
    }
  }

  private createOverlay(): OverlayRef {
    const position = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return this.overlay.create({
      positionStrategy: position,
      hasBackdrop: true,
      disposeOnNavigation: true,
      panelClass: this.themeService.getDarkValue() ? 'app-dark' : '',
    });
  }

  private getMoveUrl(): string {
    return `/wiki/${this.move.fileName}/s`;
  }

  private createInjector(): Injector {
    return Injector.create({
      providers: [{ provide: GifPlayer, useValue: this }],
    });
  }

  private seo(): void {
    this.seoService.setTags({
      title: `rlmoves - ${this.move.name}`,
    });
  }

  ngOnInit(): void {
    this.openInitially();
  }
}
