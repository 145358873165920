export interface Move {
  name: string;
  subMoves: SubMove[];
  difficulty: Difficulty;
  gifUrl: string;
  fileName: string;
  previewUrl: string;
  category: Category;
  teamOnly?: boolean;
  extraMode?: boolean;
  scorable?: boolean;
  assistable?: boolean;
  transition?: MoveTransition;
  links?: MoveLink[];
}

export type MoveStage = 'ground' | 'wall' | 'air' | 'goal' | 'finish';

export interface MoveTransition {
  from: MoveStage;
  to: MoveStage;
}

export interface MoveLink {
  icon: string;
  tooltip: string;
  url?: string;
  clipboard?: string;
  hideOnMobile?: boolean;
}

export type SubMove = {
  move: Move;
  prefix?: string;
};

export type CategoryOrMove = Category | Move;

export type Category =
  | 'Ball'
  | 'Controls'
  | 'Flips'
  | 'Dribbles'
  | 'Flicks'
  | 'Offence'
  | 'Defence'
  | 'Passing'
  | 'Kickoffs'
  | 'Pinches'
  | 'Shots'
  | 'Car control';

export const BALL: Category = 'Ball';
export const CONTROLS: Category = 'Controls';
export const FLIPS: Category = 'Flips';
export const OFFENCE: Category = 'Offence';
export const DEFENCE: Category = 'Defence';
export const PASSING: Category = 'Passing';
export const KICKOFFS: Category = 'Kickoffs';
export const PINCHES: Category = 'Pinches';
export const DRIBBLES: Category = 'Dribbles';
export const FLICKS: Category = 'Flicks';
export const SHOTS: Category = 'Shots';
export const CAR_CONTROL: Category = 'Car control';

export type Difficulty = 'Control' | 'Easy' | 'Moderate' | 'Hard' | 'Pro';
export const CONTROL: Difficulty = 'Control';
export const EASY: Difficulty = 'Easy';
export const MODERATE: Difficulty = 'Moderate';
export const HARD: Difficulty = 'Hard';
export const PRO: Difficulty = 'Pro';

export const DIFFICULTIES: Difficulty[] = [
  'Control',
  'Easy',
  'Moderate',
  'Hard',
  'Pro',
];
