<div class="app-overflow o-auto app-background h-100 w-100 vs-64 flex d-column a-center"
     appIntersection
     #scrollContainer>
  <app-container-group class="w-100">
    <app-container-group-toggle id="account"
                                [collapsed]="isSignedIn$ | async">
      Account
    </app-container-group-toggle>
    <app-container-group-toggle *ngIf="isSignedIn$ | async"
                                id="post"
                                [collapsed]="true"
                                [disabled]="signedOut$ | async">
      Post
    </app-container-group-toggle>

    <app-container class="flex a-center"
                   [absoluteTitle]="true"
                   [collapsed]="isSignedIn$ | async"
                   id="account">
      <app-container-title> Account </app-container-title>
      <app-container-content>
        <app-login class="w-100"></app-login>
      </app-container-content>
    </app-container>

    <app-container *ngIf="user$ | async"
                   [absoluteTitle]="true"
                   [collapsed]="true"
                   id="post">
      <app-container-title> Post </app-container-title>
      <app-container-content>
        <app-post-factory (posted)="onPosted($event)"></app-post-factory>
      </app-container-content>
    </app-container>
  </app-container-group>

  <h2 class="flex a-center hs-16 hoverable c-pointer"
      (click)="removeQuery()"
      *ngIf="queryLabel$ | async as queryLabel">
    <div>{{ queryLabel }}</div>
    <app-icon icon="remove"></app-icon>
  </h2>

  <app-post-display *ngFor="let post of posts$ | async; trackBy: trackByFn; let last = last"
                    [entity]="post"
                    appIntersectionNode
                    (appIntersectionNodeIntersecting)="
      onIntersectionChanged($event, post)
    "
                    (removed)="onRemoved($event)">
  </app-post-display>

  <div class="app-post-last app-secondary-text"
       *ngIf="lastPostId === '-MXn1pnygbWVdwnXEP1Z'">
    Oh no, you scrolled to the last post! Why not post something?
  </div>
</div>
