<span class="hoverable c-pointer" [appPopper]="details" (click)="onNickClicked()">
  {{ nick$ | async }}
</span>

<ng-template #details>
  <div
    class="flex d-column"
  >
    <h2>{{ nick$ | async }}</h2>

    <span>
      <span class="app-secondary-text">Boost: </span>
      <span>{{ likes$ | async }}</span>
    </span>
  </div>
</ng-template>
