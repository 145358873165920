export function removeFromArray<T>(toRemove: T, array: T[]): T[] {
  const arrayCopy = [...array];
  arrayCopy.splice(array.indexOf(toRemove), 1);
  return arrayCopy;
}

export function updateInArray<T>(newValue: T, target: T, array: T[]): T[] {
  const index = array.indexOf(target);

  if (index < 0) {
    return array;
  }

  const arrayCopy = [...array];
  arrayCopy[index] = newValue;
  return arrayCopy;
}

export function addToArray<T>(toAdd: T, array: T[], numberOf = 1): T[] {
  if (toAdd) {
    return [...array, ...Array(numberOf).fill(toAdd)];
  }

  return [...array];
}
