import { Injectable, Injector } from '@angular/core';

import { CrudApi } from 'angular-fire-utils';

import { Contribute } from './contribute-model';

@Injectable({
  providedIn: 'root',
})
export class ContributeService extends CrudApi<Contribute> {
  constructor(protected injector: Injector) {
    super(injector, 'contribute');
  }
}
