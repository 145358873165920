import { NgModule } from '@angular/core';
import {
  AERIAL,
  AIR_DRIBBLE,
  AIR_DRIBBLE_BUMP,
  AIR_PITCH_DOWN,
  AIR_PITCH_UP,
  AIR_ROLL,
  AIR_ROLL_FLICK,
  AIR_ROLL_LEFT_OR_RIGHT,
  AIR_ROLL_SHOT,
  AIR_STEER,
  BACKBOARD_PASS,
  BACKFLIP,
  BACKFLIP_SHOT,
  BACK_FLICK,
  BACK_PASS,
  BALL_BOUNCING,
  BALL_DRIBBLING,
  BALL_FLYING,
  BALL_ROLLING,
  BLOCK,
  BOOST,
  BOUNCE_DRIBBLE,
  BOUNCE_SHOT,
  BREEZI_FLICK,
  BUMP,
  CANCELLED_BACKFLIP,
  CANCELLED_FRONT_FLIP,
  CATEGORIES_AND_MOVES_PROVIDER,
  CEILING_PINCH,
  CEILING_RESET,
  CEILING_SAVE,
  CEILING_SHOT,
  CHAIN_DASH,
  CORNER_PASS,
  CURVEDASH,
  DEMOLITION,
  DIAGONAL_FLICK,
  DIAGONAL_FLIP,
  DOOMSEE_DISH,
  DOUBLE_JUMP,
  DOUBLE_TAP,
  DOUBLE_TOUCH,
  DRIBBLE,
  DRIBBLE_AIR_DRIBBLE,
  DRIVE,
  DUNK,
  FAKE,
  FAST_AERIAL,
  FIFTY_FIFTY,
  FLIP_CANCEL_SHOT,
  FLIP_RESET,
  FORTYFIVE_FLICK,
  FRIENDSHIP_KICKOFF,
  FRONT_FLICK,
  FRONT_FLIP,
  GROUND_PINCH,
  GUILLOTINE_PASS,
  HALF_FLIP,
  HEL_JUMP,
  HOOK_SHOT,
  INFIELD_PASS,
  JUMP,
  KICKOFF,
  KUXIR_PINCH,
  MUSTY_FLICK,
  NAVIGATOR_TARGETS_PROVIDER,
  NINETY_FLICK,
  ONE_EIGHTY_FLICK,
  POGO,
  POP,
  POP_AIR_DRIBBLE,
  POP_PASS,
  POP_SHOT,
  POST_PINCH,
  POWERSLIDE_DRIBBLE,
  POWER_SHOT,
  POWER_SLIDE,
  PREFLIP_SHOT,
  RECOVERY,
  REDIRECT,
  REDIRECT_KICKOFF,
  REDIRECT_SAVE,
  SAVE,
  SHOT,
  SIDE_FLICK,
  SIDE_FLIP,
  SOFT_BLOCK,
  SONIC_FLIP,
  SPEED_FLIP,
  STALL,
  STEER,
  TEAM_AIR_PINCH,
  TEAM_PINCH,
  TORNADO_SPIN,
  TRIPPING,
  WALL_AIR_DRIBBLE,
  WALL_CLEAR,
  WALL_PINCH,
  WAVEDASH,
  WAVEDASH_DRIBBLE,
  WAVEDASH_KICKOFF,
} from './moves-providers';

@NgModule({
  providers: [
    DRIVE,
    STEER,
    JUMP,
    BOOST,
    POWER_SLIDE,
    BALL_ROLLING,
    BALL_BOUNCING,
    BALL_FLYING,
    BALL_DRIBBLING,
    DOUBLE_JUMP,
    AERIAL,
    FAST_AERIAL,
    TORNADO_SPIN,
    AIR_ROLL,
    AIR_STEER,
    AIR_PITCH_DOWN,
    AIR_PITCH_UP,
    AIR_ROLL_LEFT_OR_RIGHT,
    RECOVERY,
    CEILING_RESET,
    POGO,
    FLIP_RESET,
    BACKFLIP,
    FRONT_FLIP,
    DIAGONAL_FLIP,
    SIDE_FLIP,
    CANCELLED_BACKFLIP,
    CANCELLED_FRONT_FLIP,
    HALF_FLIP,
    STALL,
    HEL_JUMP,
    SPEED_FLIP,
    SONIC_FLIP,
    WAVEDASH,
    CURVEDASH,
    CHAIN_DASH,
    POP,
    FRONT_FLICK,
    DIAGONAL_FLICK,
    SIDE_FLICK,
    BACK_FLICK,
    BREEZI_FLICK,
    FORTYFIVE_FLICK,
    NINETY_FLICK,
    ONE_EIGHTY_FLICK,
    AIR_ROLL_FLICK,
    MUSTY_FLICK,
    SHOT,
    AIR_ROLL_SHOT,
    POP_SHOT,
    POWER_SHOT,
    DOUBLE_TOUCH,
    PREFLIP_SHOT,
    FLIP_CANCEL_SHOT,
    BOUNCE_SHOT,
    HOOK_SHOT,
    BACKFLIP_SHOT,
    CEILING_SHOT,
    REDIRECT,
    DOUBLE_TAP,
    WALL_CLEAR,
    DRIBBLE,
    BOUNCE_DRIBBLE,
    DOOMSEE_DISH,
    AIR_DRIBBLE,
    WALL_AIR_DRIBBLE,
    POP_AIR_DRIBBLE,
    DRIBBLE_AIR_DRIBBLE,
    WAVEDASH_DRIBBLE,
    POWERSLIDE_DRIBBLE,
    KICKOFF,
    WAVEDASH_KICKOFF,
    REDIRECT_KICKOFF,
    FRIENDSHIP_KICKOFF,
    CATEGORIES_AND_MOVES_PROVIDER,
    BLOCK,
    DUNK,
    FIFTY_FIFTY,
    TRIPPING,
    BUMP,
    AIR_DRIBBLE_BUMP,
    FAKE,
    DEMOLITION,
    GROUND_PINCH,
    WALL_PINCH,
    KUXIR_PINCH,
    TEAM_PINCH,
    TEAM_AIR_PINCH,
    POST_PINCH,
    CEILING_PINCH,
    BACK_PASS,
    INFIELD_PASS,
    CORNER_PASS,
    POP_PASS,
    GUILLOTINE_PASS,
    BACKBOARD_PASS,
    SAVE,
    REDIRECT_SAVE,
    CEILING_SAVE,
    SOFT_BLOCK,
    NAVIGATOR_TARGETS_PROVIDER,
  ],
})
export class MovesModule {}
