import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  filter,
  map,
  startWith,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import {
  AppValidators,
  getErrorFromFromGroup,
  getLastUrlPart,
  urlToPostUrl,
} from '@rlmoves/core';
import { AuthService, Post, PostService, User } from '@rlmoves/api';

import { CanLeak } from '../mixins';

@Component({
  selector: 'app-post-factory',
  templateUrl: './post-factory.component.html',
  styleUrls: ['./post-factory.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostFactoryComponent extends CanLeak {
  @Output() posted = new EventEmitter<Post>();

  formGroup = new FormGroup({
    title: new FormControl(null, [
      Validators.required,
      Validators.maxLength(24),
    ]),
    url: new FormControl(null, [Validators.required, AppValidators.gifUrl]),
    player: new FormControl(null, [AppValidators.liquipediaUrl]),
  });

  user$ = this.authService
    .getSignedInUser()
    .pipe(filter((user): user is User => !!user));

  titleError$ = getErrorFromFromGroup('title', this.formGroup).pipe(
    map((error) => {
      return error?.maxlength
        ? `Too long (${error.maxlength.actualLength}/${error.maxlength.requiredLength})`
        : '';
    })
  );

  urlError$ = getErrorFromFromGroup('url', this.formGroup).pipe(
    map((error) => error?.expected ?? '')
  );

  playerError$ = getErrorFromFromGroup('player', this.formGroup).pipe(
    map((error) => error?.expected ?? '')
  );

  invalid$ = this.formGroup.statusChanges.pipe(
    startWith(null),
    map(() => this.formGroup.invalid)
  );

  private postSub = new Subject<void>();

  post$: Observable<Post> = this.postSub.pipe(
    withLatestFrom(this.user$),
    filter(([, user]) => this.formGroup.valid && !!user),
    map(([, user]) => {
      const url = urlToPostUrl(this.formGroup.value.url);
      const player = this.formGroup.value.player;
      return (
        url && {
          ...this.formGroup.value,
          id: this.postService.createPushId(),
          url,
          player: player && getLastUrlPart(player),
          userId: user?.id,
        }
      );
    }),
    filter((post): post is Post => !!post)
  );

  constructor(
    private authService: AuthService,
    private postService: PostService
  ) {
    super();
    this.subscribe();
  }

  onPost(): void {
    this.postSub.next();
  }

  private subscribe(): void {
    this.post$.pipe(takeUntil(this.destroySub)).subscribe((post) => {
      this.postService.push(post);
      this.formGroup.reset();
      this.posted.emit(post);
    });
  }
}
