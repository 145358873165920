import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { startWith, take } from 'rxjs/operators';
import { DisplaysEntity } from 'angular-fire-utils';

import { User, UserService } from '@rlmoves/api';
import { QueryService } from '@rlmoves/core';

@Component({
  selector: 'app-user-display',
  templateUrl: './user-display.component.html',
  styleUrls: ['./user-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDisplayComponent extends DisplaysEntity<User> {
  nick$ = this.pluck('nick');
  likes$ = this.pluck('likes').pipe(
    startWith(0)
  );

  constructor(
    protected userService: UserService,
    private queryService: QueryService
  ) {
    super(userService);
  }

  onNickClicked(): void {
    this.id$
      .pipe(take(1))
      .subscribe((id) => this.queryService.query({ key: 'userId', value: id }));
  }
}
