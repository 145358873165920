import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  TrackByFunction,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  animationFrameScheduler,
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
} from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  scan,
  share,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { SeoService } from 'angular-fire-utils';

import { AuthService, Post, PostService, UserService } from '@rlmoves/api';
import { CanLeak } from '@rlmoves/components';
import {
  deepDistinctUntilChanged,
  getVoteCount,
  QueryService,
} from '@rlmoves/core';

import { POST_PAGE_SIZE, POST_VOTES_THRESHOLD } from './post-model';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-post d-block h-100',
  },
})
export class PostComponent extends CanLeak {
  @ViewChild('scrollContainer', { read: ElementRef })
  private scrollContainer: ElementRef<HTMLElement>;

  isSignedIn$ = this.authService.getIsSignedIn();
  user$ = this.authService.getSignedInUser();

  queryLabel$ = this.queryService.getPostQuery().pipe(
    switchMap((query) => {
      if (query?.key === 'userId') {
        return this.userService
          .get(query.value)
          .pipe(map((user) => `Posts by ${user?.nick}`));
      }

      if (query?.key === 'player') {
        return of(`Plays by ${query.value}`);
      }

      return of('');
    }),
    startWith('')
  );

  signedOut$ = this.user$.pipe(
    map((user) => user === null),
    startWith(true)
  );

  private intersectionSub = new BehaviorSubject<string | undefined>(
    this.getInitialRouterId()
  );

  private clientPostSub = new BehaviorSubject<Post | null>(null);
  private clientRemovedSub = new BehaviorSubject<string | null>(null);

  lastPostId?: string;
  private lastSortedPostId?: string;

  private byQuery$ = this.queryService.getPostQuery().pipe(
    deepDistinctUntilChanged(),
    switchMap((query) => {
      return query
        ? this.postService.getPageByQuery(20, query).pipe(take(1))
        : of([]);
    }),
    startWith([])
  );

  private byPostId$ = this.intersectionSub.pipe(
    distinctUntilChanged(),
    filter(
      (postId) => !this.lastSortedPostId || postId === this.lastSortedPostId
    ),
    map((postId) => this.lastPostId || postId),
    withLatestFrom(this.byQuery$),
    filter(([, byQuery]) => byQuery.length === 0),
    map(([id]) => id),
    switchMap((id) => {
      return this.postService.getPage(POST_PAGE_SIZE, id).pipe(take(1));
    }),
    // Use tap to get around circular piping
    tap((posts) => {
      this.lastPostId = posts[posts.length - 1]?.id || this.lastPostId;
    }),
    map((posts) => {
      return posts
        .filter((post) => getVoteCount(post.reactions) > POST_VOTES_THRESHOLD)
        .sort((a, b) => {
          // Always put the first post first, since it might have been linked
          if (a === posts[0]) {
            return -1;
          } else if (b === posts[0]) {
            return 1;
          }

          return getVoteCount(b.reactions) - getVoteCount(a.reactions);
        });
    }),
    scan((acc, curr) => [...acc, ...curr], [] as Post[]),
    tap((posts) => (this.lastSortedPostId = posts[posts.length - 1]?.id))
  ) as Observable<Post[]>; // tap changes Observable type for some reason

  private clientPosts$ = this.clientPostSub.pipe(
    scan((acc, curr) => (curr ? [...acc, curr] : acc), [] as Post[]),
    startWith([])
  );

  private clientRemovedPosts$ = this.clientRemovedSub.pipe(
    scan((acc, curr) => (curr ? [...acc, curr] : acc), [] as string[]),
    startWith([])
  );

  posts$ = combineLatest([
    this.byPostId$,
    this.byQuery$,
    this.clientPosts$,
    this.clientRemovedPosts$,
  ]).pipe(
    map(([byPostId, byQuery, clientPost, clientRemoved]) => {
      const posts = [...clientPost, ...byPostId].filter(
        (post) => !clientRemoved.some((id) => post.id === id)
      );

      return byQuery.length > 0 ? byQuery : posts;
    }),
    share()
  );

  trackByFn: TrackByFunction<Post> = (index: number, post: Post) => post.id;

  constructor(
    private postService: PostService,
    private authService: AuthService,
    private userService: UserService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private queryService: QueryService,
    private seoService: SeoService
  ) {
    super();
    this.subscribe();
    this.seo();
  }

  onRemoved(id: string): void {
    this.clientRemovedSub.next(id);
    this.postService.delete(id);
  }

  onIntersectionChanged(
    intersecting: boolean,
    post: Post,
  ): void {
    if (intersecting) {
      this.intersectionSub.next(post.id);
    }
  }

  removeQuery(): void {
    this.queryService.query(null);
  }

  onPosted(post: Post): void {
    this.clientPostSub.next(post);
  }

  private subscribe(): void {
    combineLatest([this.intersectionSub, this.posts$])
      .pipe(takeUntil(this.destroySub))
      .subscribe(([intersection, posts]) => {
        const index = posts.findIndex((post) => post.id === intersection);
        this.location.replaceState(
          `/post/${index > 0 ? posts[index - 1].id || '' : ''}`
        );
      });

    this.byQuery$
      .pipe(delay(0, animationFrameScheduler), takeUntil(this.destroySub))
      .subscribe(() => (this.scrollContainer.nativeElement.scrollTop = 0));
  }

  private getInitialRouterId(): string | undefined {
    const entries = performance.getEntriesByType(
      'navigation'
    ) as PerformanceNavigationTiming[];

    if (entries[0] && entries[0].type !== 'navigate') {
      this.location.replaceState('/post/');
      return undefined;
    }

    return this.activatedRoute.snapshot.paramMap.get('id') || undefined;
  }

  private seo(): void {
    this.seoService.setTags({
      title: 'rlmoves - Posts',
    });
  }
}
