import { Directive } from '@angular/core';

@Directive({
  selector: 'input',
  host: {
    class: 'app-input app-background app-text app-border w-100',
    '[attr.autocomplete]': 'off',
  },
})
export class InputDirective {}
