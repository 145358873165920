import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Output,
} from '@angular/core';
import {
  AuthService,
  AVAILABLE_REACTIONS,
  Reactions,
  UserService,
} from '@rlmoves/api';
import { getUserReactions, getVoteCount } from '@rlmoves/core';
import { BehaviorSubject, combineLatest, merge, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CanLeak } from '../mixins';

@Component({
  selector: 'app-reaction-display',
  templateUrl: './reaction-display.component.html',
  styleUrls: ['./reaction-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-reaction-display flex a-center j-space-between h-100',
  },
})
export class ReactionDisplayComponent extends CanLeak {
  @Input()
  set reactions(value: Reactions | null) {
    this.reactionsSub.next(value);
  }
  private reactionsSub = new BehaviorSubject<Reactions | null>(null);

  @Input()
  set userId(value: string | null) {
    if (value !== null) {
      this.userIdSub.next(value);
    }
  }
  private userIdSub = new BehaviorSubject<string | null>(null);

  userReactions$ = this.reactionsSub.pipe(
    map((reactions) => (reactions ? getUserReactions(reactions) : []))
  );

  user$ = this.authService.getSignedInUser();

  isSignedOut$ = this.authService.getIsSignedOut();

  count$ = this.reactionsSub.pipe(
    map((reactions) => (reactions ? getVoteCount(reactions) : 0))
  );

  private voteSub = new Subject<boolean | null>();

  private reactionSub = new Subject<string>();

  downVote$ = this.voteSub.pipe(
    filter((vote) => vote === false),
    map(() => '-1')
  );

  upVote$ = this.voteSub.pipe(filter((vote) => vote === true));

  removeVote$ = this.voteSub.pipe(
    filter((vote): vote is null => vote === null)
  );

  availableReactions$ = merge(
    this.upVote$.pipe(map(() => AVAILABLE_REACTIONS)),
    this.reactionSub.pipe(map(() => null))
  );

  @Output()
  vote = merge(this.downVote$, this.reactionSub, this.removeVote$);

  noUpVote$ = combineLatest([this.reactionsSub, this.user$]).pipe(
    map(([reactions, user]) => {
      return (
        !user ||
        !reactions ||
        reactions[user.id] === undefined ||
        reactions[user.id] === '-1'
      );
    })
  );

  noDownVote$ = combineLatest([this.reactionsSub, this.user$]).pipe(
    map(([reactions, user]) => {
      return !user || !reactions || reactions[user.id] !== '-1';
    })
  );

  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {
    super();
    this.subscribe();
  }

  onReaction(reaction: string): void {
    this.reactionSub.next(reaction);
  }

  onVote(vote: boolean | null): void {
    this.voteSub.next(vote);
  }

  private subscribe(): void {
    this.vote
      .pipe(
        withLatestFrom(this.userIdSub, this.user$, this.reactionsSub),
        takeUntil(this.destroySub)
      )
      .subscribe(([vote, userId, user, reactions]) => {
        if (
          user &&
          reactions &&
          this.getVoteDelta(reactions[user.id]) === this.getVoteDelta(vote)
        ) {
          return;
        }

        if (userId) {
          this.userService.changeLike(userId, this.getVoteDelta(vote));
        }
      });

    // To show reaction on client directly
    combineLatest([this.vote, this.user$])
      .pipe(takeUntil(this.destroySub))
      .subscribe(([vote, user]) => {
        if (!user) {
          return;
        }

        if (this.reactionsSub.value) {
          delete this.reactionsSub.value[user.id];

          if (vote) {
            this.reactionsSub.value[user.id] = vote;
          }
        }

        this.reactionsSub.next({
          ...this.reactionsSub.value,
        });
      });
  }

  private getVoteDelta(vote: string | null): number {
    return vote === '-1' ? -1 : vote === null ? 0 : 1;
  }
}
