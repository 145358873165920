import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';

import { CategoryOrMove, ContributeService } from '@rlmoves/api';
import {
  AppValidators,
  CATEGORIES_AND_MOVES,
  Theme,
  ThemeService,
} from '@rlmoves/core';
import { DialogService } from '@rlmoves/components';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: Theme, useExisting: HomeComponent }],
  host: {
    class: 'app-home d-block app-text app-background h-100',
  },
})
export class HomeComponent {
  themeIcon$ = this.themeService
    .getDark()
    .pipe(map((isDark) => (isDark ? 'sunrise' : 'eclipse')));

  themeTooltip$ = this.themeService
    .getDark()
    .pipe(map((isDark) => (isDark ? 'Go light' : 'Go dark')));

  topNav = [
    // {
    //   link: '/post',
    //   name: 'post',
    // },
    {
      link: '/wiki',
      name: 'wiki',
    },
    {
      link: '/freestyle',
      name: 'freestyle',
    },
  ];

  contributeFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    url: new FormControl('', [
      Validators.required,
      AppValidators.ballchasingUrl,
    ]),
  });

  hasUrlError$ = this.contributeFormGroup.controls.url.statusChanges.pipe(
    map(() => !!this.contributeFormGroup.controls.url.invalid)
  );

  @ViewChild('contribute', { read: TemplateRef })
  private contributeTemplateRef: TemplateRef<any>;

  constructor(
    @Inject(CATEGORIES_AND_MOVES)
    public categoriesOrMoves: CategoryOrMove[],
    private themeService: ThemeService,
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    private contributeService: ContributeService
  ) {}

  toggleTheme(): void {
    this.themeService.toggleDarkTheme();
  }

  openDialog(): void {
    this.dialogService.open(this.contributeTemplateRef, this.viewContainerRef);
  }

  postContribution(): void {
    if (!this.contributeFormGroup.valid) {
      return;
    }

    this.contributeService.push(this.contributeFormGroup.value);
    this.dialogService.close();
  }
}
