import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ThemeService } from '@rlmoves/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-logo flex hs-16',
  },
})
export class LogoComponent {
  isLight$ = this.themeService.getLight();

  constructor(private themeService: ThemeService) {}
}
