export function getLocalStorageJson<T>(key: string, notFoundValue: T): T {
  const item = localStorage.getItem(key);

  if (item) {
    return JSON.parse(item);
  }

  return notFoundValue;
}

export function setLocalStorageJson(key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export const IS_DARK_KEY = 'isDark';
