import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  ContainerModule,
  IconModule,
  LoginModule,
  PostFactoryModule,
} from '@rlmoves/components';

import { PostDisplayModule, IntersectionModule } from '@rlmoves/components';

import { PostComponent } from './post.component';

@NgModule({
  declarations: [PostComponent],
  imports: [
    CommonModule,
    ContainerModule,
    IconModule,
    IntersectionModule,
    LoginModule,
    PostFactoryModule,
    PostDisplayModule,
  ],
  exports: [PostComponent],
})
export class PostModule {}
