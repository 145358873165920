import { Category, Difficulty, Move, SubMove } from '@rlmoves/api';

export function moveComparator(a: Move, b: Move): number {
  return CATEGORY_ORDER[a.category] - CATEGORY_ORDER[b.category];
}

export function difficultyComparator(a: Move, b: Move): number {
  return DIFFICULTY_ORDER[a.difficulty] - DIFFICULTY_ORDER[b.difficulty];
}

export function toSubMove(subMoves: (Move | Move[])[]): SubMove[] {
  return (Array.isArray(subMoves) ? subMoves : [subMoves]).reduce(
    (acc, subMove, index) => {
      if (Array.isArray(subMove)) {
        subMove.forEach((s, i) => {
          acc.push({
            move: s,
            prefix: i === 0 ? (index === 0 ? undefined : '>') : '+',
          });
        });
      } else {
        acc.push({ move: subMove, prefix: index === 0 ? undefined : '>' });
      }

      return acc;
    },
    [] as SubMove[]
  );
}

export const CATEGORY_ORDER: Record<Category, number> = {
  Dribbles: 1,
  Shots: 2,
  Flicks: 3,
  Flips: 4,
  Pinches: 5,
  'Car control': 6,
  Kickoffs: 7,
  Offence: 8,
  Defence: 9,
  Passing: 10,
  Controls: 11,
  Ball: 12,
};

export const DIFFICULTY_ORDER: Record<Difficulty, number> = {
  Control: 1,
  Easy: 2,
  Moderate: 3,
  Hard: 4,
  Pro: 5,
};
