import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, asapScheduler } from 'rxjs';
import {
  map,
  take,
  startWith,
  filter,
  delay,
  withLatestFrom,
  takeUntil,
} from 'rxjs/operators';

import { CategoryOrMove, PostService } from '@rlmoves/api';
import {
  SMALL_BREAKPOINT,
  CATEGORIES_AND_MOVES,
  BreakpointService,
  ThemeService,
} from '@rlmoves/core';
import { CanLeak } from '@rlmoves/components';
import { SeoService } from 'angular-fire-utils';

@Component({
  selector: 'app-wiki',
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-wiki flex h-100 w-100',
  },
})
export class WikiComponent extends CanLeak {
  notMobile$ = this.breakpointService
    .getMatches()
    .pipe(map((matches) => !matches.breakpoints[SMALL_BREAKPOINT]));

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  private scrollViewPort: CdkVirtualScrollViewport;

  constructor(
    @Inject(CATEGORIES_AND_MOVES)
    public categoriesOrMoves: CategoryOrMove[],
    private activatedRoute: ActivatedRoute,
    private breakpointService: BreakpointService,
    private seoService: SeoService,
    private postService: PostService,
    private themeService: ThemeService
  ) {
    super();
    this.subscribe();
    this.seo();

    // Dummy request to track usage
    this.postService.getProperty('-MXn1pnygbWVdwnXEP1Z', 'player').subscribe();
  }

  toggleTheme(): void {
    this.themeService.toggleDarkTheme();
  }

  private subscribe(): void {
    const isUserInteraction$ = fromEvent(document.body, 'mousedown', {
      capture: true,
    }).pipe(
      take(1),
      map(() => true),
      startWith(false)
    );

    const scrollToIndex$ = this.activatedRoute.paramMap.pipe(
      map((paramMap) => paramMap.get('id')),
      filter((id) => !!id),
      delay(0),
      map((id) => {
        return Math.max(
          this.categoriesOrMoves.findIndex((categoryOrMove) =>
            typeof categoryOrMove === 'string'
              ? categoryOrMove === id
              : categoryOrMove.fileName === id
          ) - 1,
          0
        );
      })
    );

    scrollToIndex$
      .pipe(
        delay(0, asapScheduler),
        withLatestFrom(isUserInteraction$),
        takeUntil(this.destroySub)
      )
      .subscribe(([index, isUserInteraction]) => {
        this.scrollViewPort.scrollToIndex(
          index,
          isUserInteraction ? 'smooth' : 'auto'
        );
      });
  }

  private seo(): void {
    this.seoService.setTags({
      title: 'rlmoves - Wiki',
    });
  }
}
