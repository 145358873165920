import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '@rlmoves/core';

import { ExternalPlayerComponent } from './external-player.component';

@NgModule({
  declarations: [ExternalPlayerComponent],
  exports: [ExternalPlayerComponent],
  imports: [CommonModule, PipesModule],
})
export class ExternalPlayerModule {}
