import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';

import { CategoryOrMove } from '@rlmoves/api';
import { CATEGORIES_AND_MOVES } from '@rlmoves/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-nav app-border o-auto',
  },
})
export class NavComponent {
  moves = this.categoriesAndMoves.map((categoryOrMove) => {
    return {
      isMove: typeof categoryOrMove !== 'string',
      name:
        typeof categoryOrMove === 'string'
          ? categoryOrMove
          : categoryOrMove.name,
      link:
        typeof categoryOrMove === 'string'
          ? categoryOrMove
          : categoryOrMove.fileName,
    };
  });

  constructor(
    @Inject(CATEGORIES_AND_MOVES)
    public categoriesAndMoves: CategoryOrMove[]
  ) {}
}
