import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { MoveDisplayComponent } from './move-display.component';
import { ContainerModule } from '../container/container.module';
import { GifPlayerModule } from '../gif-player';
import { LinkModule } from '../link/link.module';
import { IconModule } from '../icon';
import { PopperModule } from '../popper';

@NgModule({
  declarations: [MoveDisplayComponent],
  exports: [MoveDisplayComponent],
  imports: [
    ClipboardModule,
    CommonModule,
    GifPlayerModule,
    ContainerModule,
    IconModule,
    PopperModule,
    RouterModule,
    LinkModule,
  ],
})
export class MoveDisplayModule {}
