<app-icon
  *ngIf="compact"
  icon="play"
></app-icon>

<img
  class="w-100 h-100"
  [class.app-gif-player-icon]="compact"
  [src]="previewUrl"
/>
