import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';

import { NavigatorModule } from '@rlmoves/core';
import {
  MoveDisplayModule,
  NavModule,
  ContainerModule,
} from '@rlmoves/components';

import { WikiComponent } from './wiki.component';

@NgModule({
  declarations: [WikiComponent],
  exports: [WikiComponent],
  imports: [
    CommonModule,
    MoveDisplayModule,
    NavModule,
    NavigatorModule,
    ContainerModule,
    ScrollingModule,
    RouterModule,
  ],
})
export class WikiModule {}
