<app-nav class="app-wiki-nav"
         *ngIf="notMobile$ | async">
</app-nav>

<cdk-virtual-scroll-viewport class="app-overflow o-auto app-background h-100 f-1"
                             [itemSize]="200">
  <div class="app-wiki-virtual-item flex d-column j-flex-end w-100"
       *cdkVirtualFor="
      let categoryOrMove of categoriesOrMoves;
      templateCacheSize: 0; let first = first
    ">
    <app-move-display class="h-100"
                      *ngIf="categoryOrMove.name; else category"
                      [move]="categoryOrMove">
    </app-move-display>

    <ng-template #category>
      <div class="flex j-space-between">
        <h2>{{ categoryOrMove }}</h2>
        <h2 *ngIf="(notMobile$ | async) && first">
          Type to search...
        </h2>
      </div>
    </ng-template>
  </div>
</cdk-virtual-scroll-viewport>

<app-navigator></app-navigator>