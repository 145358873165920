import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of, from } from 'rxjs';
import { switchMap, map, shareReplay, startWith } from 'rxjs/operators';

import { User } from './user-model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private signedInUser$ = this.auth.user.pipe(
    switchMap((user) => (user ? this.userService.get(user.uid) : of(null))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private isSignedIn$ = this.auth.user.pipe(
    map((user) => user !== null),
    startWith(true)
  );

  private isSignedOut$ = this.isSignedIn$.pipe(
    map((isSignedIn) => !isSignedIn)
  );

  constructor(
    private auth: AngularFireAuth,
    private userService: UserService
  ) {}

  sendPasswordResetEmail(email: string): void {
    this.auth.sendPasswordResetEmail(email);
  }

  getSignedInUser(): Observable<User | null> {
    return this.signedInUser$;
  }

  getIsSignedIn(): Observable<boolean> {
    return this.isSignedIn$;
  }

  getIsSignedOut(): Observable<boolean> {
    return this.isSignedOut$;
  }

  logIn(email: string, password: string) {
    return from(this.auth.signInWithEmailAndPassword(email, password));
  }

  logOut(): void {
    this.auth.signOut();
  }

  signUp(email: string, password: string) {
    return from(this.auth.createUserWithEmailAndPassword(email, password));
  }
}
