<div class="app-bingo-board-item app-border p-16 c-pointer p-relative"
     *ngFor="let move of activeMoves; let i = index"
     [class.app-bingo-board-item-active]="move.active"
     (click)="toggle(i)">

  <div [class.medium-opaque]="move.active">{{move.move.name}}</div>

  <app-icon class="app-bingo-board-item-check"
            *ngIf="move.active"
            icon="check">
  </app-icon>

  <app-gif-player *ngIf="!move.active"
                  [move]="move.move"
                  [compact]="true"
                  (click)="$event.stopPropagation()">
  </app-gif-player>
</div>