import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getLocalStorageJson, IS_DARK_KEY, setLocalStorageJson } from './local-storage';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private isDarkSub = new BehaviorSubject<boolean>(
    getLocalStorageJson(IS_DARK_KEY, true)
  );

  getDark(): Observable<boolean> {
    return this.isDarkSub.asObservable();
  }

  getLight(): Observable<boolean> {
    return this.isDarkSub.pipe(
      map(isDark => !isDark)
    );
  }

  getDarkValue(): boolean {
    return this.isDarkSub.value;
  }

  toggleDarkTheme(): void {
    setLocalStorageJson(IS_DARK_KEY, !this.isDarkSub.value);
    this.isDarkSub.next(!this.isDarkSub.value);
  }
}
