import { ChangeDetectorRef, Directive, Input, Optional } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CanLeak } from '../mixins';
import { ContainerGroupComponent } from './container-group.component';

@Directive()
export class ContainerCollapsedBase extends CanLeak {
  @Input() id: string;
  @Input() collapsed: boolean | null;

  constructor(
    @Optional() protected containerGroupComponent: ContainerGroupComponent,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.subscribe();
  }

  private subscribe(): void {
    if (!this.containerGroupComponent) {
      return;
    }

    this.containerGroupComponent
      .getToggled()
      .pipe(takeUntil(this.destroySub))
      .subscribe((id) => this.collapsed = this.id === id ? !this.collapsed : true);
  }
}
