import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  BingoBoardModule,
  ButtonGroupModule,
  ButtonModule,
  IconModule,
  MoveDisplayModule,
} from '@rlmoves/components';

import { FreestyleComponent } from './freestyle.component';

@NgModule({
  declarations: [FreestyleComponent],
  exports: [FreestyleComponent],
  imports: [
    BingoBoardModule,
    ButtonModule,
    ButtonGroupModule,
    CommonModule,
    IconModule,
    MoveDisplayModule,
    ReactiveFormsModule,
  ],
})
export class FreestyleModule {}
