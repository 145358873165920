import { Entity } from 'angular-fire-utils';

import { Reactions } from '../models/reaction-model';

export interface Post extends Entity {
  title: string;
  url: PostUrl;
  userId: string;
  reactions: Reactions;
  player?: string;
}

export interface PostUrl {
  id: string;
  type: PostUrlType;
}

export enum PostUrlType {
  YouTube,
  Gfycat,
  Gyg,
}

export interface PostQuery {
  key: string;
  value: string;
}
