<app-container class="flex j-space-between h-100" [absoluteTitle]="true">
  <app-container-title>
    {{ move.name }}
  </app-container-title>

  <app-container-content class="app-move-display-submoves">
    <div class="flex z1" *ngFor="let subMove of move.subMoves">
      <div class="app-move-display-prefix" *ngIf="subMove.prefix">
        {{ subMove.prefix }}
      </div>

      <app-link [routerLink]="'/wiki/' + subMove.move.fileName">
        {{ subMove.move.name }}
      </app-link>
    </div>
  </app-container-content>

  <app-container-full>
    <app-gif-player class="h-100" [move]="move" [compact]="mobile$ | async"></app-gif-player>
  </app-container-full>

  <app-container-subtitle>
    <ng-container *ngFor="let link of move.links">
      <app-link
        *ngIf="!link.hideOnMobile || !mobileMatches"
        [href]="link.url || null"
        [appPopper]="link.tooltip"
        [cdkCopyToClipboard]="link.clipboard || ''"
      >
        <app-icon [icon]="link.icon" size="small"> </app-icon>
      </app-link>
    </ng-container>
  </app-container-subtitle>
</app-container>
