<app-toolbar class="flex a-center j-space-between">
  <div class="app-home-top-nav flex">
    <h1 *ngFor="let nav of topNav"
        class="o-none medium-opaque c-pointer hoverable"
        [routerLink]="nav.link"
        routerLinkActive="opaque">
      {{ nav.name }}
    </h1>
  </div>

  <div class="app-home-title-container flex a-center hs-16">
    <div class="app-home-icon-container">
      <div class="flex hs-8">
        <app-icon icon="add"
                  size="small"
                  verticalAlign="middle"
                  appPopper="Contribute a move"
                  (click)="openDialog()">
        </app-icon>

        <app-icon icon="mail"
                  size="small"
                  href="https://www.reddit.com/message/compose/?to=blidblid"
                  appPopper="Ping the developer">
        </app-icon>

        <app-icon [icon]="themeIcon$ | async"
                  size="small"
                  verticalAlign="middle"
                  (click)="toggleTheme()"
                  [appPopper]="themeTooltip$ | async">
        </app-icon>
      </div>
    </div>

    <app-logo class="c-pointer"
              routerLink=""> </app-logo>
  </div>
</app-toolbar>

<router-outlet></router-outlet>

<ng-template #contribute>
  <div class="flex d-column p-32">
    <div class="app-home-contribute-form flex j-space-between vs-32 w-wrap"
         [formGroup]="contributeFormGroup">
      <app-form-field class="w-100">
        <app-form-field-label>Move name</app-form-field-label>
        <input formControlName="name"
               appInput />
      </app-form-field>

      <app-form-field class="w-100">
        <app-form-field-label>Ballchasing replay url</app-form-field-label>
        <input formControlName="url"
               appInput />
        <app-form-field-error *ngIf="hasUrlError$ | async">
          Input a ballchasing.com url
        </app-form-field-error>
      </app-form-field>
    </div>

    <div class="flex j-space-between vs-32">
      <div></div>

      <button (click)="postContribution()"
              [disabled]="contributeFormGroup.invalid">
        Submit
      </button>
    </div>
  </div>
</ng-template>
