import { AbstractControl, ValidationErrors } from '@angular/forms';
import { POST_URL_HANDLERS } from './url';

export class AppValidators {
  static gifUrl(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    return POST_URL_HANDLERS.some((handler) => handler.match(control.value))
      ? null
      : { expected: 'GifYourGame URL', actual: control.value };
  }

  static liquipediaUrl(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    return /.*liquipedia\.net\/rocketleague\/.*/.exec(control.value)
      ? null
      : { expected: 'Liquipedia URL', actual: control.value };
  }

  static ballchasingUrl(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    return /.*ballchasing\.com\/replay\/.*/.exec(control.value)
      ? null
      : { expected: 'Ballchasing URL', actual: control.value };
  }
}
