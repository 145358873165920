import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { Location } from '@angular/common';
import { BreakpointService, MOBILE_BREAKPOINT } from '@rlmoves/core';
import { map } from 'rxjs/operators';
import { Move, SubMove } from '@rlmoves/api';

@Component({
  selector: 'app-move-display',
  templateUrl: './move-display.component.html',
  styleUrls: ['./move-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-move-display d-block',
  },
})
export class MoveDisplayComponent {
  @Input() move: Move;

  get mobileMatches() {
    return this.breakpointService.mobileMatches();
  }

  mobile$ = this.breakpointService
    .getMatches()
    .pipe(map((matches) => matches.breakpoints[MOBILE_BREAKPOINT]));
    
  constructor(
    private location: Location,
    private breakpointService: BreakpointService
  ) {}

  navigateToMove(subMove: SubMove): void {
    this.location.replaceState(`/${subMove.move.fileName}/s`);
  }
}
