<app-container class="w-100" [vertical]="true">
  <app-container-title class="flex j-space-between">
    <h2>{{ title$ | async }}</h2>
    <app-icon
      *ngIf="removable$ | async"
      icon="remove"
      size="small"
      [appPopper]="remove"
      appPopperTrigger="enter"
      (click)="onRemoveClicked()"
    ></app-icon>
  </app-container-title>

  <app-container-secondary-title class="flex j-space-between">
    <div>
      poster: <app-user-display [id]="userId$ | async"></app-user-display>
    </div>
    <div *ngIf="player$ | async as player">
      player:
      <span class="c-pointer hoverable" (click)="onPlayerClicked(player)">
        {{ player }}
      </span>
    </div>
  </app-container-secondary-title>

  <app-container-content class="app-post-display-content">
    <app-reaction-display
      appHiddenOverflow
      [reactions]="reactions$ | async"
      [userId]="userId$ | async"
      (vote)="onVote($event)"
    >
    </app-reaction-display>
  </app-container-content>

  <app-container-full>
    <svg viewBox="0 0 16 9"></svg>
    <app-external-player [postUrl]="url$ | async"></app-external-player>
  </app-container-full>
</app-container>

<ng-template #remove>
  <div>
    {{ removeLabel$ | async }}
  </div>
</ng-template>
