import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

import { SeoTag } from './seo-model';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private metaService: Meta, private titleService: Title) {}

  removeTags(tags: string[]): void {
    tags.forEach((tag) => this.metaService.removeTag(tag));
  }

  setTags(tag: Partial<SeoTag>) {
    const twitterTags = [
      { property: 'twitter:card', content: tag.description },
      { property: 'twitter:title', content: tag.title },
      { property: 'twitter:description', content: tag.description },
      { property: 'twitter:image', content: tag.image },
    ];

    const ogTags = [
      { property: 'og:type', content: tag.type },
      { property: 'og:title', content: tag.title },
      { property: 'og:site_name', content: tag.name },
      { property: 'og:description', content: tag.description },
      { property: 'og:image', content: tag.image },
    ];

    ogTags.push({ property: 'og:video', content: tag.video });
    ogTags.push({ property: 'og:video:url', content: tag.video });
    ogTags.push({ property: 'og:video:secure_url', content: tag.video });

    if (tag.title) {
      this.titleService.setTitle(tag.title);
    }

    [...twitterTags, ...ogTags].forEach((tag) => {
      if (tag.content !== undefined) {
        this.metaService.updateTag(tag as MetaDefinition);
      }
    });
  }
}
