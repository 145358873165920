import { Injectable } from '@angular/core';

import { Move, MoveStage } from '@rlmoves/api';

import { FLIP_RESET } from '../moves';
import { getRandomItem } from '../util';
import {
  MAX_MOVE_COMBINATION,
  MOVE_COMBINATION_CONTINUE_CHANCE,
} from './move-combinator-model';

@Injectable({
  providedIn: 'root',
})
export class MoveCombinatorService {
  create(moves: Move[], startAt: Move[] = []): Move[] {
    if (startAt.length === 0) {
      startAt.push(getRandomItem(moves));
    }

    const current = startAt[startAt.length - 1];

    if (!current.transition) {
      return startAt;
    }

    if (
      !current ||
      Math.random() > MOVE_COMBINATION_CONTINUE_CHANCE ||
      startAt.length === MAX_MOVE_COMBINATION ||
      current.transition?.to === 'finish'
    ) {
      return startAt;
    }

    let pushed = false;

    const pushFromDifficulty = (stage: MoveStage) => {
      const item = getRandomItem(
        moves.filter(
          (move) =>
            move.transition?.from === stage &&
            (move === FLIP_RESET.useValue || move !== current)
        )
      );

      if (item) {
        startAt.push(item);
      }

      pushed = !!item;
    };

    if (current.transition?.to === 'ground') {
      pushFromDifficulty('ground');
    } else if (current.transition?.to === 'air') {
      pushFromDifficulty('air');
    } else if (current.transition?.to === 'wall') {
      pushFromDifficulty('wall');
    } else if (current.transition?.to === 'goal') {
      pushFromDifficulty('goal');
    }

    return pushed ? this.create(moves, startAt) : startAt;
  }
}
