import { Injectable } from '@angular/core';
import { PostQuery } from '@rlmoves/api';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  private postQuerySub = new Subject<PostQuery | null>();

  getPostQuery() {
    return this.postQuerySub.asObservable();
  }

  query(query: PostQuery | null): void {
    this.postQuerySub.next(query);
  }
}
