import { Directive, ElementRef, Output } from '@angular/core';
import { IntersectionDirective } from './intersection.directive';

@Directive({
  selector: '[appIntersectionNode]',
})
export class IntersectionNodeDirective {
  @Output('appIntersectionNodeIntersecting')
  intersecting$ = this.intersection.observe(this.elementRef.nativeElement);

  constructor(
    private intersection: IntersectionDirective,
    private elementRef: ElementRef
  ) {}
}
