import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from '../button';
import { ContainerComponent } from './container.component';
import { ContainerTitleComponent } from './container-title.component';
import { ContainerGroupComponent } from './container-group.component';
import { ContainerGroupToggleComponent } from './container-group-toggle.component';
import { ContainerFullComponent } from './container-full.component';
import { ContainerContentComponent } from './container-content.component';
import { ContainerSecondaryTitleComponent } from './container-secondary-title.component';
import { ContainerSubtitleComponent } from './container-subtitle.component';

const API = [
  ContainerComponent,
  ContainerContentComponent,
  ContainerFullComponent,
  ContainerGroupComponent,
  ContainerGroupToggleComponent,
  ContainerTitleComponent,
  ContainerSubtitleComponent,
  ContainerSecondaryTitleComponent,
];

@NgModule({
  declarations: API,
  exports: API,
  imports: [CommonModule, ButtonModule],
})
export class ContainerModule {}
