import { ALL_REACTIONS, Reactions, UserReaction } from '@rlmoves/api';

export function getUserReactions(reactions: Reactions): UserReaction[] {
  return Object.keys(reactions)
    .filter((key) => reactions[key] !== '-1')
    .map((key) => {
      return {
        userId: key,
        reaction: ALL_REACTIONS[reactions[key]],
      };
    });
}

export function getVoteCount(reactions: Reactions): number {
  if (!reactions) {
    return 0;
  }

  return Object.values(reactions).reduce(
    (acc, curr) => acc + (curr === '-1' ? -1 : 1),
    0
  );
}
