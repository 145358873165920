import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { SeoService } from 'angular-fire-utils';

import {
  capitalize,
  MOVE,
  MoveCombinatorService,
  shuffle,
} from '@rlmoves/core';
import { Move } from '@rlmoves/api';

import {
  DEFAULT_FREESTYLE_CATEGORY,
  FORBIDDEN_FREESTYLE_MOVES,
  FREESTYE_DIFFICULTIES,
  FREESTYLE_CATEGORIES,
  FREESTYLE_TEAM_FLAG,
} from './freestyle-model';

@Component({
  selector: 'app-freestyle',
  templateUrl: './freestyle.component.html',
  styleUrls: ['./freestyle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-freestyle flex d-column a-center h-100 w-100 o-auto app-background',
  },
})
export class FreestyleComponent implements OnDestroy {
  categories = FREESTYLE_CATEGORIES;
  difficulties = FREESTYE_DIFFICULTIES;

  categoryFormControl = new FormControl([
    capitalize(
      this.activatedRoute.snapshot.paramMap.get('id') ||
        DEFAULT_FREESTYLE_CATEGORY
    ),
  ]);
  difficultyFormControl = new FormControl(FREESTYE_DIFFICULTIES);

  selectedDifficulties$: Observable<string[]> =
    this.difficultyFormControl.valueChanges.pipe(
      startWith(this.difficultyFormControl.value)
    );

  selectedMode$ = this.categoryFormControl.valueChanges.pipe(
    startWith(this.categoryFormControl.value),
    map((value) => value[0])
  );

  moves$ = this.selectedDifficulties$.pipe(
    map((difficulties) =>
      this.moves.filter((move) =>
        difficulties.some((difficulty) => difficulty === move.difficulty)
      )
    )
  );

  bingoMoves$ = this.moves$.pipe(
    map((moves) => {
      return moves.reduce((acc, curr) => {
        if (curr.scorable) {
          acc.push({
            ...curr,
            name: `${curr.name} ${curr.teamOnly ? 'into ' : ''}Goal`,
          });
        }

        if (curr.assistable) {
          acc.push({ ...curr, teamOnly: true, name: `${curr.name} Assist` });
        }

        if (!curr.assistable && !curr.scorable) {
          acc.push(curr);
        }

        return acc;
      }, [] as Move[]);
    }),
    map((moves) =>
      shuffle(
        moves.filter(
          (move) =>
            !move.teamOnly ||
            this.difficultyFormControl.value.includes(FREESTYLE_TEAM_FLAG)
        )
      )
    )
  );

  horseMoves$ = this.moves$.pipe(
    map((moves) => shuffle(moves)),
    map((moves) =>
      this.moveCombinator.create(moves.filter((move) => !move.teamOnly))
    )
  );

  private destroySub = new Subject<void>();

  constructor(
    @Inject(MOVE) private moves: Move[],
    private moveCombinator: MoveCombinatorService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private seoService: SeoService
  ) {
    this.moves = this.moves.filter(
      (move) => !move.extraMode && !FORBIDDEN_FREESTYLE_MOVES.includes(move)
    );

    this.subscribe();
    this.seo();
  }

  refresh(category: string): void {
    if (category === this.categoryFormControl.value[0]) {
      this.difficultyFormControl.setValue(this.difficultyFormControl.value);
    }
  }

  private subscribe(): void {
    this.categoryFormControl.valueChanges
      .pipe(
        startWith(this.categoryFormControl.value),
        takeUntil(this.destroySub)
      )
      .subscribe((category) =>
        this.location.replaceState(`/freestyle/${category[0].toLowerCase()}`)
      );
  }

  private seo(): void {
    this.seoService.setTags({
      title: 'rlmoves - Freestyle',
    });
  }

  ngOnDestroy(): void {
    this.destroySub.next();
    this.destroySub.complete();
  }
}
